import vetochkaIcon from '../../assets/images/icons/06_vetochka_icon.png';
import frameIcon from '../../assets/images/icons/frame01.png';
import img00 from '../../assets/images/articles/06_vetochka_00.png';
import img01 from '../../assets/images/articles/06_vetochka_01.png';
import img02 from '../../assets/images/articles/06_vetochka_02.png';
import img03 from '../../assets/images/articles/06_vetochka_03.png';
import img04 from '../../assets/images/articles/06_vetochka_04.png';
import img05 from '../../assets/images/articles/06_vetochka_05.png';
import img06 from '../../assets/images/articles/06_vetochka_06.png';
import imgPhoto from '../../assets/images/articles/06_vetochka_ph.png';
import imgUzor from '../../assets/images/articles/06_vetochka_uzor.png';

const vetochka = {
    id: '06',
    link: 'vetochka',
    icon: vetochkaIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [
        {
            image: img00,
            label: 'Шаман из рода Нгамтусуо — Демниме Дюходович Костеркин. 1970-е годы. Автор Г. Н. Грачева'
        },
        {
            image: img01,
            label: 'Дедушка — Демниме Дюходович Костеркин,  бабушка — Хярку Яловна Костеркина. 1965 год.  Автор Б. К. Елогирь'
        },
        {
            image: img02,
            label: 'Мои родные в своем стойбище.1970-е годы. Автор Г. Н. Грачева'
        },
        {
            image: img03,
            label: 'Наши домашние олени.1970-е годы. Автор Г. Н. Грачева'
        },
        {
            image: img04,
            label: 'В п. Усть-Авам. Середина 1970-х годов. Автор Г. Н. Грачева'
        },
        {
            image: img05,
            label: 'Я в национальной люльке-колыбели. 1969 год. Автор Г. Н. Грачева'
        },
        { image: img06, label: 'С усть-авамскими девушками' }
    ],
    title: 'Сломаю веточку — прошу у куста прощения',
    name: 'Лидия Кундылеевна Аксёнова',
    role: 'Старший научный сотрудник КГБУК «Таймырский краеведческий музей»',
    nation: 'нганасанка',
    authors: {
        title: 'Подготовили',
        names: 'Екатерина Алексеенко, Арина Мышева, Никита Пишванов',
        mediaschool: 'Дудинка',
        photoNum: 'many',
        photoNames: 'из семейного архива Л. К. Аксёновой'
    },
    text: [
        'Нганасаны — крайне малочисленный народ, зато очень древний. По всероссийской переписи 2010 года на Таймыре нас насчитывалось всего 747 человек. Мы — потомки охотников за диким северным оленем, появившихся здесь, по мнению археологов, этнографов, ученых, около 6000 лет назад.',
        'Говорят, что нганасаны — самый загадочный этнос Севера. Возможно. Даже наша одежда сильно отличается от национальной одежды соседних народов. Больше ни у кого в мире нет такой обуви — без выема, в форме цилиндрического мешка. В подобной обуви удобно бегать по глубокому снегу, а пальцы не так быстро замерзают во время сурового мороза.',
        'Если говорить про особенности национального характера, то нганасаны всегда держали нейтралитет в спорах между соседними коренными народами. При конфликтах они помалкивали — их и не трогали. Но если тронешь нганасан — начнется война! Можно подумать, что нганасаны тихие, равнодушные ко всему, но на самом деле они всё держат в голове, долго обдумывают, что сказать или сделать, и уж потом принимают решение. Мы всегда продолжительное время присматриваемся к новому человеку, переглядываемся, решаем, можно ли иметь с ним дело и говорить о своем личном хозяйстве или семье.',
        'Раньше нганасан считали воинственным народом, но ведь при нападении защищать свою территорию, стойбище, своих родных будет каждый!',
        'С рождения до шести лет я прожила в стойбище у бабушки и дедушки. Приезжая к родителям, замечала, что тундровые кочевые нганасаны отличаются от оседлых, живущих в поселке. В тундре, если заходишь в чум и видишь, что там сидят люди старше тебя, ты должна подойти к самому старшему и подставить щеку. У нас отсутствовало слово «здравствуйте», это в современном языке его перевели с русского. А в то время вместо приветствия подходили и подставляли щеку. Старший целовал тебе одну щеку, потом вторую. Вот так здоровались.',
        'Чистокровных нганасан осталось очень мало. В основном это люди старшего поколения — «пятидесятники», «шестидесятники». Им иногда трудно воспринимать новое. Когда моей родственнице дали квартиру в городе, на втором или третьем этаже, она спросила: «Как я буду здесь жить?» Ей говорят: «Все нормально, вот у тебя вода в кране, туалет и все что нужно!» Она отвечает: «Нет, надо мной люди ходят!»',
        'Нельзя было, чтобы над ней кто-то ходил и она над кем-то. У северных народов, в частности у самодийских, женщина всегда считалась нечистой. Естественно, она не могла жить так, чтобы над кем-то ходить. Может, внизу мужчины живут, есть принадлежащие им предметы? А женщине нельзя проходить над мужскими вещами! Например, если в чуме лежит мужская обувь или, там, нож, а женщина их перешагнет, то все — они испорчены! Мужчине-хозяину остается только эти вещи сломать и выкинуть в тундре. И женщина-нганасанка это знала. Она или обходила эти предметы, или ждала, когда мужчина уберет.',
        'Сейчас нганасаны в тундре не живут. Большинство уже в поселках или в городах. Моей маме 82 года. Когда мы жили в поселке, естественно, тоже соблюдали некоторые обычаи. Если детская шапочка падала на пол, мы ее брали и очищали над печкой, а в чуме — над огнем. Вообще, если ты уронил что-то на землю, то Мать-земля, дух Земли может это забрать. А шапка соприкасается с головой ребенка! Надо быстро схватить и над костром очистить. В городе мы тоже кое-что из обычаев соблюдаем. После стирки белье развешиваем на веревках между кухней и прихожей, но только не мужское исподнее! У меня две внучки, нельзя, чтобы такие вещи оказывались над их головами.',
        'Я бы не сказала, что у нганасан главой семьи является мужчина. Он только добытчик, а главная все-таки женщина, хозяйка чума. Поддержание порядка, воспитание детей, шитье одежды и прочее — все на ней. Кажется, что женщина-нганасанка, бедная, только тем и занимается, что за костром следит, еду готовит, шьет и так далее. А на самом деле многое без разрешения хозяйки мужчина сделать не может. Даже шаман! Жена шамана — помощница при камлании и хранительница шаманского одеяния. Без ее разрешения муж не сможет камлать. Был такой случай. Приехали в стойбище кинодокументалисты, чтобы снять фильм. Шаман говорит жене: «Давай вытаскивай такой-то костюм». А у нее настроения нет. Вот нет и все, не хочет она. И все ждали, когда у нее настроение появится и она даст разрешение. Полтора дня ждали…',
        'Я — внучка шамана Демниме Костеркина. И в детстве стыдилась этого. У других мама — воспитательница детского сада, папа — председатель сельсовета или колхозник. Это почетно. А шаманы тогда считались изгоями общества. Поэтому дедушка жил в 12 километрах от поселка. И только когда я стала студенткой, узнала, что он, оказывается, был буквально кладезем мудрости, знал гораздо больше, чем мы сейчас! Шаманы могли лечить, не являясь медиками. Могли послать улов рыбакам. Кажется, что это все легенды. Но легенды просто так не придумываются, не появляются! Видимо, шаманы из нашего рода могли это делать. Но они не были всесильны. Как остановить эпидемию оспы, например, не знали.',
        'Духи тундры, конечно, существуют. Просто люди отдалились от того мира, в котором жили наши дедушки и бабушки, и перестали с ними общаться. Раньше, чтобы взять воду из озера или реки, нужно было обратиться к духу Воды: «Пойми, пожалуйста, вода нужна моей семье, внукам, детям на чай, суп». И потом уже набирать. Если я, идя по тундре, случайно задеваю веточку на кусте и она ломается, прошу у куста прощения. У его духа. И внучек воспитываю так же.',
        'Чтобы знать язык, нужна практика. Нужно постоянно слышать родную речь. Даже я уже не могу так свободно говорить, как в детстве. Везде звучит русский. У нас в поселке не было школы. В шесть лет меня посадили в вертолет и отправили в Дудинку, в интернат, где учились и жили дети коренных народов из тундры. До этого я даже в садик не ходила. Нас было четверо детей в семье, и папа не разрешал маме отправлять нас в сад, мол, «они забудут свой родной язык». Так что из русских слов я знала только «здравствуйте», «пошли» и еще что-то такое. В интернате все старшеклассники разговаривали на русском. Для меня это был шок. Приходилось очень тяжело. Мы старались говорить на своем языке, но по тогдашней политике это не приветствовалось. А сейчас у нганасанских школьников родной язык — как иностранный. Они больше слов знают на английском, чем на родном.',
        'У нганасан необязательно вступать в брак со своим. Во-первых, потому что нас мало. Для меня было табу выходить замуж за родственников по материнской и по отцовской линии. Нужно было найти парня из другого рода. А еще нганасаны очень тесно жили с долганами. Вместе в одном поселке, кочевали тоже рядом. Естественно, что нганасанки порой выходили замуж за долган, это даже поощрялось у моего народа: новая кровь. У тех, кто состоял в смешанном браке с 1950–1960-х годов, национальность почему-то наследовалась от матери. Если мама нганасанка, а папа долганин, то ребенок именно нганасан. Теперь национальность считается по отцу. У меня муж долганин — дочка долганка получилась. Когда я развелась, мы с дочкой жили в поселке у моей мамы, где она слышала от бабушки нганасанскую речь. В школе учила долганский язык, но в 10-м классе вдруг сказала: «Знаешь, мама, мне нужно было в нганасанскую группу идти. Этот мир и культура мне ближе».',
        'Помню, в Ленинграде во время учебы в институте я как-то вышла из метро и почувствовала запах нашей северной корюшки, пахнущей свежим огурцом. Думаю: господи, откуда? Пошла на запах, купила рыбы, принесла в общежитие, нажарила. А там в основном ребята из районов Крайнего Севера, Сибири и Дальнего Востока, где тоже корюшку уважают. Все прибежали в мою комнату, и мы устроили пир. То, что ели в детстве, так любимым и осталось.',
        'Все нынешние проблемы малочисленных народов — от оседлости, от интерната, от утери домашних оленей. Вот ненцы до сих пор кочуют со своими домашними стадами с одного места на другое, так их дети хорошо говорят на родном языке. Кто не хочет жить в городе, а хочет в тундре, тот, естественно, знает, как пастушить, следить за оленями, как их ловить. А после интерната молодым, конечно, уже не хочется кочевой жизни. Они городскую цивилизацию увидели: вода есть, тепло, готовят повара, убираются уборщицы… Какой старшеклассник при такой жизни пойдет пасти стадо, зачем ему это? Оставались пастушить старики, у которых проблемы со зрением, слухом, ногами. Полстада упустили, полстада волки загрызли… В итоге нганасаны утеряли домашнего оленя. Я считаю, что надо жить в тундре, заниматься традиционными промыслами. Тогда, возможно, и язык сохранится, и сам народ будет жив!'
    ]
};

export default vetochka;
