import React from 'react';
import classes from './articlePage.module.css';
import allPeople from '../../../data/articles/allPeople';

const ArticlePage = ({ person }) => {
    const personObj = allPeople.find((item) => item.link === person);
    const { name, title, role } = personObj;
    return (
        <div className={classes.articleWrap}>
            <div className={classes.titleWrap}>{title}</div>
            <>
                {!Array.isArray(name) ? (
                    <div className={classes.nameRoleWrap}>
                        <div>
                            <p className={classes.nameWrap}>{name}</p>
                            <p className={classes.roleWrap}>{role}</p>
                        </div>
                    </div>
                ) : (
                    <div className={classes.nameRoleWrap}>
                        <div>
                            <p className={classes.nameWrap}>{name[0]}</p>
                            <p className={classes.roleWrap}>{role[0]}</p>
                        </div>
                        <div>
                            <p className={classes.nameWrap}>{name[1]}</p>
                            <p className={classes.roleWrap}>{role[1]}</p>
                        </div>
                    </div>
                )}
            </>
        </div>
    );
};

export default ArticlePage;
