import React, { useEffect, useState } from 'react';
import classes from './card.module.css';
import Aos from 'aos';

const Card = ({ person, index, crossfade }) => {
    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);
    const [isHover, setIsHover] = useState(false);
    const { icon, frame, title, link } = person;
    const handleOver = () => {
        setIsHover(true);
    };
    const handleOut = () => {
        setIsHover(false);
    };
    return (
        <>
            <div
                onMouseOver={handleOver}
                onMouseOut={handleOut}
                onClick={() => crossfade('people/' + link)}
                data-aos='flip-right'
                className={
                    classes.cardWrap +
                    ' ' +
                    ((index % 7) % 2 !== 0 && classes.cardWrapDown)
                }
            >
                <img className={classes.cardWrapImg} src={icon} alt='Image' />
                <img
                    className={
                        classes.cardFrame +
                        ' ' +
                        (isHover && classes.cardFrameHover)
                    }
                    src={frame}
                    alt='Image'
                />
                <div
                    className={
                        classes.cardTitle +
                        ' ' +
                        (isHover && classes.cardTitleHover)
                    }
                >
                    {title}
                </div>
            </div>
            {(index + 1) % 7 === 0 && <>&nbsp;</>}
        </>
    );
};

export default Card;
