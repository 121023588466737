import danceIcon from '../../assets/images/icons/66_dance_icon.png';
import frameIcon from '../../assets/images/icons/frame04a.png';
import imgPhoto from '../../assets/images/articles/66_dance_ph.png';
import imgUzor from '../../assets/images/articles/66_dance_uzor.png';
import img01 from '../../assets/images/articles/66_dance_01.png';
import img02 from '../../assets/images/articles/66_dance_02.png';
import img03 from '../../assets/images/articles/66_dance_03.png';
import img04 from '../../assets/images/articles/66_dance_04.png';

const dance = {
    id: '66',
    link: 'dance',
    icon: danceIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [
        { image: img01, label: '' },
        { image: img02, label: '' },
        { image: img03, label: '' },
        { image: img04, label: '' }
    ],
    title: 'Многие не считают танцы работой. Советуют оленей пасти',
    name: ['Даяна Клещина', 'Наталья Чуприна'],
    role: [
        'Балетмейстер заслуженного коллектива РФ «Национальный ансамбль песни и танца народов Севера “Хэйро”»',
        'Вокалист и руководитель вокальной группы «Сулускан»'
    ],
    nation: ['ненка', 'долганка'],
    authors: {
        title: 'Подготовила',
        names: 'Майя Балыбина',
        mediaschool: 'Дудинка',
        photoNum: 'many',
        photoNames: 'Даяна Клещина, Лада Мягкова, Римантас Рамошка'
    },
    text: [
        'Наталья: Я из Хатангского района, из поселка Сындасско, оттуда прилетела сюда, в колледж поступила на социально-культурное воспитание. До третьего курса вообще не знала, что такое «Хэйро». Даже не думала, что приду сюда работать, никак себя не видела здесь. А потом как-то сложилось, что в 2007-м окончила колледж и пришла сюда. В школе даже не пела — для меня было редкоcтью, чтобы на сцену выпустили, и с музыкой ничего не связывало. И тут вдруг оказалось, что я умею петь. Все приходит со временем: меня начали учить, я узнала, что такое фольклор, что такое современное пение. А про этническое узнала уже в «Хэйро».',
        'Даяна: А я с детства танцую, как и мой младший брат. У меня мама большой ценитель ненецкой культуры. Сама я из Караула. И мама нас приучала к культуре с самого детства. Сперва я занималась северным многоборьем, потом мама сказала: «Даяночка, иди танцуй!» Думаю: «Ну ладно, пойду», — и уже где-то в 14–15 лет я сама ставила танцы, а мы с мамой создали свой ансамбль «Сарю Тэс». Окончила школу, поехала в Салехард, поступила на хореографию, отучилась, танцевала в легендарнейшем ансамбле «Сыра’cэв» — это визитная карточка Ямала. Лет пять прожила в Салехарде, потом вернулась на родину и попала в «Хэйро». Про него я знала еще в детстве, мы частенько ездили в Дудинку на какие-то фестивали, конкурсы. Мама у меня поет, песни пишет сама. На ненецком. Даже легенды какие-то придумывает. По национальности ненка, папа тоже ненец. В «Хэйро» я уже почти три года. У меня есть студия «Альта» при нем — в ней занимаются все желающие от 14 до 30 лет.',
        'И сейчас учусь заочно в Екатеринбурге — управлению сферой культуры, руководству учреждениями. Учиться сложно, но мне нравится. Свое будущее связываю с этим — не всю ведь жизнь танцевать! Наверное, еще года два, потом попреподаю где-нибудь. Надо успокоить тело…',
        'Наталья: А я вот, честно, не могу сказать, сколько еще тут проработаю, но я уже… как бы это правильнее выразиться? Да, скорее, напелась. Хочется видеть более молодых. Мне ведь не 25, как ей',
        '(кивает на подругу). Но сейчас с этим очень трудно — дети не знают родного языка, соответственно, мало кто поет. Конечно, танцорам больше повезло, чем нам, поющим. Они телом все это показывают, а мы голосом. Мы больше связаны с языком. Язык — наше все… А молодежь современная вся в гаджетах, и они не хотят изучать свой родной язык. Приходится показывать на своем примере.',
        'От восьми до 11 лет — максимум, когда дети могут петь на родном языке. А потом у них переходный возраст, и все, сразу отстраняются: «Я не долганка! Я не знаю родной язык!» Хотя до этого года два прекрасно на нем пела.',
        'Даяна: Многие стесняются… Знаете почему? Бывает же, что к коренному населению отношение другое, как бы это сказать, слегка насмешливое, что ли.',
        'Наталья: Ну скорее, да, что вот так пальцем могут ткнуть: ой, смотрите, она поет. А уже маленьким деткам тяжело дается, потому что родители не говорят на родном языке. Он для них как иностранный. Вот у меня сын, ему сейчас восемь, лет до четырех он понимал долганский, так как постоянно в поселок ездил. Понимал, но не говорил. А я всегда родителей просила: говорите с ним на родном языке. Но потом все, четыре исполнилось ему — и «Я не хочу!» Начал говорить, что он русский. Я говорю: «Сына, почему ты русский? Мама-то у тебя долганка!» «Мама, мама, я же говорю на русском языке, значит, я русский!» Сейчас все-таки повзрослел, начал понимать, согласился: «Да, я долганин».',
        'А я ведь ему с рождения песни пела! Даже, наверное, когда он был в животе, я постоянно пела вечерами. И всегда на родном языке.',
        'Даяна: Каждому ребенку мама поет о том, каким он должен вырасти. У каждого своя колыбельная получается, личная. Вот и у энцев нет хоровых песен, потому что у каждого своя. Мужчины тоже поют.',
        'Наталья: На охоту едут — поют, да…  Дорóгой. Все что вижу, то и пою. Не нужно ни рифмы, ничего. Даже слух не особо нужен — сам себе мелодию напридумывал и поет. Ноты? Да вы что, никогда и не было их! Все на слух только. Тебя же никто не слышит, без разницы, как ты будешь петь. Ну если олени только разбегутся. (Смеются.) Тундра все стерпит.',
        'Даяна: Вообще, всегда считалось, что ненцы не танцующая нация. И народного танца у них как такового нет. Только пели всю жизнь. Но на самом деле где-то же взяли элементы у мастериц — это ведь тоже хореография, когда она скоблит. И все из быта, из традиций перешло в хореографию под пластику танцевальную. И теперь они затанцевали. У ненцев очень хороший музыкальный слух. Насколько я знаю, они все поющие. И очень хорошо сочиняют! Такие вот сказочники.',
        'Наталья: А вот у долган есть пляска — хоровод, он же и есть хэйро — это такой возглас при встрече солнца.',
        'Даяна: И у нганасан тоже хоровод, но медвежий танец: они подкрикивают, ходят… Суровый прямо такой танец. В нашем репертуаре он есть.',
        'Имеется, правда, одна проблема — в хореографии, вы знаете, не принято заворачивать стопу внутрь, а у нас, у местных, в основном она завернута — если обращали внимание, то мы косолапим обычно, а это же не эстетично. Но сейчас работают над нами — и все заходили нормально, все затанцевали. Хотя балет нам все равно тяжелее дается, другая техника и ощущения другие.',
        'Зато сейчас можно контемпорари (современный стиль сценического танца. — Прим. автора) внедрить в национальный танец! Я смотрю с хореографической точки зрения на движения шамана, и мне прямо хочется туда модерна добавить. Но у нас такого в репертуаре пока нет, потому что танцоры без хореографического образования. Приходится удерживаться в рамках и исходить из возможностей артиста. Либо надо взять новенького человечка и из него уже лепить. А так если он протанцевал 20 лет, ему это тяжело. Но может быть, я как раз смогу родить новый стиль. Таймырский контемп?.. Надо подумать… Было бы классно!',
        'Танцы ставим так: сперва изучаешь народности, практически все время это делаю… Вот мы с вами друг с другом разговариваем, вы меня понимаете, а я должна поставить танец так, чтобы вы меня поняли без слов. Плохо, когда танец без сюжета — просто помахал руками, потопал, и никто ничего не понял. А у меня специфика такая, что я хочу рассказать историю, хочу говорить, но — телом.',
        'Даяна: Мы здесь очень востребованы. Это же визитная карточка! То праздник Енисея, то открытие театрального сезона, то концерт самого «Хэйро»… Сейчас вот молодой состав пришел — из них можно что-то слепить, очень талантливые ребята. Хотя если честно, текучка большая, каждый год людей набираем. Причин несколько… Скажем, многие не воспринимают это как работу: «Чего там, ногами подрыгал, лучше бы оленя пошел пасти. Да я сейчас тоже выйду спляшу!»',
        'Наталья: Было время, появлялись у нас мальчики. Они думали: «Ой, потанцуем тут немного, на-на-на». Приходили пару раз — и пропадали. Они даже не предполагали, наверное, что тут все сурово: репетиции, каждый танец отрабатывать, каждое движение. Каждый день!',
        'Даяна: Как на работу: к девяти, в десять станок и репетиции до пяти тридцати. С обедом, все как положено. Из прошлого состава вот только один мальчик и остался. Хотя у нас в хореографии вообще не принято делить на женское и мужское: ой, ты мальчик, а танцуешь, несерьезное это занятие и все такое… Танцоры — это один пол!',
        'Можно прийти без специального образования, прямо с улицы. Главное — чтобы ты слышал, чтобы хотя бы два раза топнуть мог. Здесь вот еще в чем проблема: нет училища, где бы они обучались хореографии! На Таймыре нету ни одного!',
        'Наталья: Только в колледже на социально-культурной деятельности, но там нет специального хореографического отделения — учат всему понемножку.',
        'Я вот когда в 2003-м туда поступила, моя группа была первая, с которой что-то начали делать. Мы были как подопытные кролики. Никто не понимал, с чего начать…',
        'Даяна: С педагогами напряженка, это да… А вот открыли бы хореографию отдельно, чтобы учили именно на хореографа, — они были бы востребованы однозначно! Я работала, кстати, в колледже — преподавала хореографию и методику работы с творческим коллективом. Недолго, два месяца. Четкой задачи там не хватало: кого мы готовим, кем они оттуда выйдут — певцами или хореографами? Или все-таки режиссерами?',
        'Наталья: На инструментах, мне кажется, сейчас не учат играть. Нас учили на пианино, аккордеоне, баяне. Есть же национальные — барган, бубен. Струнных, кстати, на Таймыре нет.',
        'Даяна: Может, потому что холодно? Хотя вот ханты же играют как-то…',
        'Наталья: И духовых нет. Некогда нам было, наверное. Тащи это потом с собой на отдельных нартах! Главное, что есть барган, — побарганил и успокоился.',
        'Даяна: Ага, душу отвел. Но барганят не все. У меня мама вот барганит просто так, для себя.',
        'Наталья: Меня папа учил в детстве. У него барган был большой, тяжелый такой, железный. У меня и свой есть — иногда дрынькаю на нем. Помню, достала как-то, хотела что-то придумать — сыночка увидел, у него глаза так и загорелись… Ни у одного другого инструмента такого звука нет! Космического. Но жаль, что профессионалов почти не осталось… Вот в Якутии — да, мне кажется, там одни барганисты…',
        'Даяна: Костюмы нам шьют костюмеры. Легкие, удобные. Это не то, что наша традиционная одежда — тяжелая, неповоротливая, поэтому мы вот так и ходим…',
        'Наталья: Но отделку я могу смастерить и сама — украсить бисером, например. Вот смотрите: пояс и ободок — все сама! А унтайки эти старые, еще до моего прихода сшиты. Представляете, сколько им лет — 13–14! А сносу нет. Это, по-моему, весенний олень. А нет, даже летний! Потому что, видите, тут коротенький мех, а зимние — они такие пушистые. Удобные, но подошва тяжеловата…',
        'Даяна: А у меня на ногах тоже долганские унтайки — ненцы не используют бисер вообще. Чисто танцевальные — подошва мягкая. Как балеточки… Ай, тут заплатка нужна!',
        'Даяна: Исполняем танцы всех северных народов! Хотя, правда, может быть танец ненецкий, а в костюме элементы долганские. Вообще это криминал, да. Но мне кажется, сейчас уже не так строго на это смотрят. А вот когда училась в институте, бывало, подойдет к тебе старик и начнет: ты почему надела селькупскую парку, а, допустим, обувь у тебя не ненецкая? Поэтому я сейчас не надела долганский ободок! (Смеется). На мне же наряд ненецкий, а ободка ненецкого нет!',
        'Для своей студии я не ограничиваюсь только танцами народов, живущих на Таймыре, — беру весь Север. Вот «Хэйро» отвечает именно за Таймыр, а у меня география шире. Сейчас ставлю ительменский танец под брата — учитываю его харизму, его возможности, все под него делаю, все перерабатываю, пережевываю, перевариваю и потом только ему в рот кладу, чтобы он это проглотил!',
        'Даяна: Костюмы нам шьют в основном в Москве. Мы же тщательно прорабатываем орнаменты, цвета, ведь человеку, который здесь не живет, очень сложно разобраться в наших тонкостях. Частенько бывает, что я сначала вижу костюм, а потом уже придумываю танец!',
        'Наталья: У нас есть костюмер, но он один по всему ДК, а не только для ансамбля. Раньше был свой…',
        'Наталья: Есть у нас еще и такое дело: ставим кукольные спектакли. Когда-то у нас работал Алексей Иванович Смирнов, удивительно творческий человек. В 2010 году он придумал сюжет, сам сделал кукол и попросил меня принять участие. И пошло-поехало! Потом он ушел на пенсию, а мне директор и говорит: «Наталья, может, ты возьмешь кукольный? Я никого не вижу, кроме тебя, кто смог бы продолжить…» Вот и продолжаю до сих пор.',
        'Куклы у нас ростовые. Сегодня их рисует наш главный художник, костюмер шьет костюмы, а рабочий Сергей палочки стругает, ручки мастерит. Получается, не выходя за пределы Дома культуры, все делаем силами наших талантливых работников.',
        'Первый спектакль я ставила с детьми, а сейчас — с артистами «Хэйро», они тоже будут кукловодами. Сначала репетируют свои танцы, а потом бегут сюда…',
        'Даяна: Я принимаю участие, я — рыба!',
        'Наталья: Да, у меня тут и люди, и звери… Человек десять. Артистам тоже полезно что-то новенькое попробовать, а то все танцы, танцы!',
        'Даяна: Как мы видим свое будущее? Пока остаемся здесь… Но вообще, мне кажется, я цыганка по жизни — не могу себя назвать стопроцентной таймыркой. То там, то тут — везде! Хотя я патриотка!',
        'Наталья: А я тут, конечно, навсегда! Моя душа, моя сущность — она там, в поселке Сындасско. Я — дитя тундры, хотя уже и не помню, когда была там в последний раз. Наверное, когда еще в школе училась — давно-о-о! Но у меня все равно здесь (показывает на сердце) она осталась и будет со мной всю жизнь.'
    ]
};

export default dance;
