import neznakomyeIcon from '../../assets/images/icons/54_neznakomye_icon.png';
import frameIcon from '../../assets/images/icons/frame05a.png';
import imgPhoto from '../../assets/images/articles/54_neznakomye_ph.png';
import imgUzor from '../../assets/images/articles/54_neznakomye_uzor.png';
import img01 from '../../assets/images/articles/54_neznakomye_01.png';

const neznakomye = {
    id: '54',
    link: 'neznakomye',
    icon: neznakomyeIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [{ image: img01, label: '' }],
    title: 'В поселке ты всех знаешь, а в городе сплошные незнакомые лица',
    name: 'Анна Спиридонова',
    role: 'Студентка Таймырского колледжа',
    nation: 'долганка',
    authors: {
        title: 'Подготовил',
        names: 'Влад Пономаренко',
        mediaschool: 'Дудинка',
        photoNum: 'single',
        photoNames: 'автора'
    },
    text: [
        'Я учусь в дудинском колледже. Учиться сложней, чем в школе. Там за тобой сильно следили, а в колледже гораздо меньше контроля, нужно самой себя заставлять. Ответственность больше. Я выбрала профессию воспитателя детского сада. Она нужная, востребованная, думаю, мне удастся найти работу и в Дудинке, и в поселке. Учиться мне нравится.',
        'В Дудинку я приехала в 17 лет, до четвертого класса училась в поселке Сындасско (он, кстати, самый северный в мире!), потом переехала в Хатангу и там училась до 11-го класса. Дудинка, как по мне, очень удобный город, технологичный. Жить тут намного проще по сравнению с поселком. Там, чтобы помыться, нужно накопать снега, потом растопить его… Напряжение в поселке плохое, даже телевизор нормально не посмотреть, а тут все стабильно. Но скажем, в Красноярске мне тоже не нравится. Очень большой город, слишком много машин, без гугл-карты ничего не найдешь, телефон сядет — и… все. В поселке ты всех знаешь, чувствуешь себя уверенно, а в городе идешь — сплошные незнакомые лица! Для меня это дико.',
        'Однажды летом я кочевала с родственниками. Как же чудесно в тундре по сравнению с городом! Тишина такая, что аж в ушах звенит!',
        'Летом мы живем в балкáх, потому что они легче чумов и в них не жарко. Заготавливаем припасы на зиму. Зимой охотимся на диких оленей…',
        'Сейчас в поселках национальная одежда отходит на второй план. Все носят покупные пуховики, потому что шить парку — это очень тяжелый труд, сегодня их делают в основном на продажу. Вообще, в тундру приходит все больше технологий, и они, конечно, очень популярны у молодежи. Скажем, благодаря переносным генераторам теперь можно зарядить телефон или ноутбук…',
        'Я в целом легко приняла переезд в город, а вот моя мама — нет. Она очень трудолюбивая женщина, привыкла постоянно быть занятой, а тут печку топить не надо, за дровами идти не надо, продукты в магазине продаются, поэтому она не знала, куда себя деть. Потом, конечно, привыкла…',
        'В Дудинке, кроме колледжа, я занимаюсь волонтерством, помогаю бабушкам, животным… Планирую получить высшее образование в Санкт-Петербурге, завести семью и детей. Обязательно буду прививать им нашу долганскую культуру, рассказывать легенды, сказки… Знаете, например, почему собаки воют на Луну? С этим связана легенда про девочку, которая жила в приемной семье. Эта семья ее не любила и часто выгоняла спать на улицу к собакам, с которыми девочка дружила. Однажды ночью ее послали набрать воды. В отражении она увидела Луну и попросила ее: «Мне здесь плохо, забери меня к себе!» И Луна исполнила эту просьбу. Теперь собаки воют на Луну, потому что скучают по этой девочке…'
    ]
};

export default neznakomye;
