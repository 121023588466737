import krasotaIcon from '../../assets/images/icons/70_krasota_icon.png';
import frameIcon from '../../assets/images/icons/frame05.png';
import imgPhoto from '../../assets/images/articles/70_krasota_ph.png';
import imgUzor from '../../assets/images/articles/70_krasota_uzor.png';
import img00 from '../../assets/images/articles/70_krasota_00.png';

const krasota = {
    id: '70',
    link: 'krasota',
    icon: krasotaIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [{ image: img00, label: '' }],
    title: 'Горное дело или красота?',
    name: 'Юлия Яптуне',
    role: 'Студентка Заполярного государственного университета им. Н. М. Федоровского, конструктор-модельер',
    nation: 'ненка',
    authors: {
        title: 'Подготовила',
        names: 'Александра Щелкунова',
        mediaschool: 'Норильск',
        photoNum: 'single',
        photoNames: 'автора'
    },
    text: [
        'Мне 21 год. Родилась в поселке Тухард. Родители — оленеводы, семья вела кочевой образ жизни. Когда я была маленькой, мне это нравилось. Действительно интересно — вокруг природа, олени, свежий воздух. Я очень любила  ходить с папой на охоту, помогала ему',
        'ловить рыбу. Когда подросла, поняла, что такая жизнь все-таки очень сложна для меня: например, печку затопить, кочевать (летом мы передвигались раз в три — пять дней, а зимой — раз в три — четыре недели), и захотелось жить в городе. К семи годам родители отправили меня в школу-интернат в Дудинке. Конечно, им было грустно меня отпускать, но я была не первым ребенком, которого отдали в город, поэтому никто соплей не распускал. Отучилась там я до 11-го класса и поступила в колледж в Красноярске на конструктора-модельера в сфере услуг и предпринимательства, а через три года получила диплом.',
        'Я раньше занималась шитьем, мастерила этническую и национальную одежду. Мне нравилось этим заниматься, поэтому и поступила на модельера, чтобы шить модные вещи. Когда получила диплом, меня и еще нескольких девушек из группы пригласили работать на швейном заводе. Все бы хорошо, да вот только зарплаты мизерные… Подрабатывала я там во время учебы (главным конструктором) и получала от 10 до 20 тысяч рублей, хотя работала на износ, по 12 часов. Это меня не устраивало, поэтому я решила найти что-то другое, более прибыльное. Увидела объявление «20 таймырцев поступают в “Норникель”», заинтересовалась. Вот так вернулась в Норильск и поступила в НГИИ на горное дело.',
        'Но и сейчас я подрабатываю — шью на заказ, обычно для женщин, у которых нестандартные размеры. Шить и конструировать мне нравится, этим я занимаюсь для себя, совмещать с учебой получается. Сейчас я на первом курсе, преподаватели объясняют все доступно. Не так давно мы ходили на экскурсию на рудник Комсомольский, там нам рассказали, что можно отучиться либо на горного инженера, либо на маркшейдера. Но пока я еще свой выбор не сделала, хоть склоняюсь больше к горному делу.',
        'Грустно, конечно, что пришлось вернуться в Норильск. Да, Таймыр — моя родина, но здесь так холодно! По сравнению с Красноярском здесь мало развлечений, некуда сходить. Я рада, что мои первые студенческие годы прошли именно в Красноярске. Сейчас я стала старше, всякие увеселения меня интересуют гораздо меньше, поэтому в Норильске расстраивает только погода.',
        'Я очень скучаю по тундре, там мои брат, мама и сестра, другие две сестры живут в городе. Было грустно прощаться с интернатом при поступлении в Красноярск. Все-таки 11 лет там прожила, обзавелась множеством друзей. И вообще мне больше нравится Дудинка, чем Норильск, потому что там тихо и спокойно, свежий воздух, она ближе к нашей культуре. В детстве мы с мамой шили, рисовали. Это и повлияло на профессию. Еще я танцевала в ансамбле народных танцев «Сегуней».',
        'Ненецкий язык я знаю с рождения. По-русски научилась говорить только в первом классе. В школе было очень сложно. Сидишь на уроке, учительница что-то говорит, говорит, а я вообще ничего не понимаю, просто киваю. Русский изучила в течение месяца, потому что все вокруг разговаривали на нем. Вообще я не особо грамотная. Бывает, и сейчас скажу слово неправильно или с акцентом.',
        'Конечно, я знаю много наших обычаев. До сих пор в ходу примета, что девушкам нельзя перешагивать через мужчин, наступать на их одежду. Потому что, как говорят, женщина — «поганая» и может таким образом принести неудачу мужчине. Еще девушки не должны класть свою одежду рядом с мужской — из тех же соображений.',
        'Сейчас моя главная цель — доучиться в НГИИ, а потом, наверное, хотела бы переехать на материк. Работать предполагаю стилистом или визажистом, заниматься тем, что связано с красотой. Ведь именно это я больше всего люблю!'
    ]
};

export default krasota;
