import feminiIcon from '../../assets/images/icons/62_femini_icon.png';
import frameIcon from '../../assets/images/icons/frame03.png';
import imgPhoto from '../../assets/images/articles/62_femini_ph.png';
import imgUzor from '../../assets/images/articles/62_femini_uzor.png';
import img01 from '../../assets/images/articles/62_femini_01.png';
import img02 from '../../assets/images/articles/62_femini_02.png';
import img03 from '../../assets/images/articles/62_femini_03.png';
import img04 from '../../assets/images/articles/62_femini_04.png';
import img05 from '../../assets/images/articles/62_femini_05.png';

const femini = {
    id: '62',
    link: 'femini',
    icon: feminiIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [
        { image: img01, label: '' },
        { image: img02, label: '' },
        { image: img03, label: '' },
        { image: img04, label: '' },
        { image: img05, label: '' }
    ],
    title: 'Феминисток среди долган нет',
    name: 'Дмитрий Васильевич Чуприн',
    role: 'Потомственный оленевод, в настоящее время — резчик по кости и рогу, сотрудник КГБУК «Таймырский дом народного творчества»',
    nation: 'долганин',
    authors: {
        title: 'Подготовила',
        names: 'Майя Балыбина',
        mediaschool: 'Дудинка',
        photoNum: 'many',
        photoNames: 'из архива Д. В. Чуприна, Лада Мягкова, Римантас Рамошка'
    },
    text: [
        'Я рос в попигайской тундре в семье потомственного оленевода. У меня два брата и сестра. Я — младший. Детство — как у всех тундровых детей: до семи лет в тундре, затем начальная школа, потом интернат в районном центре Хатанге. Пока учился в Попигае, родители часто навещали, приезжали в поселок за продуктами, привозили гостинцы. Это были мои любимые нацио­нальные блюда: амаха (перекрученное мясо с костным мозгом), хили (отдельно костный мозг), языки вареные замороженные (я любил их грызть) и, конечно, деликатес тундры — оленье молоко. Останавливались у бабушки. У долган принято бабушками и дедушками называть не только пап и мам родителей, но и их старших братьев и сестер. Мне очень повезло с моей бабушкой — сестрой мамы. Она была очень добрая, заботливая, мудрая. Постоянно стряпала для меня вкусные оладьи, булочки, пряники, еще и отправляла в тундру моим родителям. В хатангском интернате мы ужасно скучали по родным, по своим прирученным олешкам (аку), по тундре.',
        'А тундра — это же свобода! Когда хочешь, берешь лук и идешь на охоту. Отец сделал мне лук, я еще и в школу не ходил, у нас так было принято. Как зачем? Куропаток стрелять! Прямо возле балкóв. Олени же паслись, ямки выкапывали, и куропатки туда прилетали поживиться — как голуби бегали рядом. Ну, я не насмерть, конечно, стрелял — так, чтобы навыки охотничьи приобретать. А вечером отец возвращался из тундры и говорил: «Пошли петли на куропаток ставить». Уходили поздно, но в полнолуние в лесу светло. Если полярный день, так вообще все видно. Ходил с ним, петли ставил на куропаток, на зайцев — тоже с малолетства меня приучал.',
        'Охотническую традицию отец мне тоже передавал, я чуть подрос, мне уже и ружье дали, мелкашку. В семь — восемь лет, может, даже и раньше, начал стрелять, и меня одного в тундру отпускали. Жили мы в балкáх семьями по шесть человек, раньше семь семей нас было. Так вот я с охоты всегда сначала к старикам шел, первую добычу им относил. День начинается: я сходил, убил куропатку и им отдаю, вторую себе забираю, и так ежедневно. Потом подрос, и начались мои собственные истории. Однажды на охоту ездил на оленях с братом старшим. Помню, было лето, разбежались олени, мы еле нашли! Стадо в тысячу голов, и нужно было всех собрать вместе. Согнали, а брат меня спрашивает: «Из какого калибра хочешь выстрелить: из 12-го или 16-го?» Ну я мелкий был и выбрал 12-й, вроде как поменьше цифра, а на самом-то деле ружье это больше! Стрельнул — и в лужу свалился от отдачи! Потом боялся к 16-му подходить. Так с 12-м все время ходил, было больно, но я думал, что 16-м еще больнее будет стрелять. Потом уже один ездить начал на уток, куропаток…',
        'Отец, когда мы вместе охотились, показывал мне, какие соболи у нас. А  если на дикого оленя шли, то впереди пускали оленя домашнего. Метров на сто он мог уйти, веревка длинная: специальную такую плетенку делали (ох и кропотливая была работа!), сидели плели из кожи оленей — тоже отец учил. Неделю сидели, чтобы сто метров сплести; тоньше мизинца получалась. Так вот на сто метров отпускаем этого оленя и плетенку подергиваем: раз дернул — он должен остановиться, два дернул — он должен копытцем бить, три раза дернул — должен опять идти. На дикого оленя даже в начале 1990-х годов мы без снегоходов ходили, потому что их не было, пешком только. Так и подходили к стаду на 50 метров с этой своей приманкой: они к нам привыкали, видели, что мы вроде как просто идем рядом. Им до нас и дела не было… Главное, надо было тихо-тихо идти — отец все время об этом напоминал. Никто о таком способе не знал. Двух-трех оленей мы так выдрессировали. Потом уж я без отца, сам ходил.',
        'А дед у меня до самой старости на диких охотился. Ходить уже не мог, так дядя его на домашнего сажал, вез в тундру, дед прямо с оленьей спины стрелял. И попадал!',
        'Оленя разному научить можно. И верхом на нем ездить летом. Зимой на санях, а в начале июня мы оленей должны были учить верховой езде. И всегда меня сажали. Я на каникулы приезжал и сразу попадал на родео. Зверь необъезженный мог лечь, а мог и брыкнуть — я через рога в лужу летал не один раз. Сильно мохнатый брыкается, честно говоря. Я и под оленем был, везде, короче… Шрамы остались даже — если он по голове попадал. Да-а, вы не думайте, что они такие уж невинные! Те, которых сюда пригоняют в упряжке детей на празднике катать, они старенькие уже… Но мы любили с ребятами, когда идет аргиш — длинный, как поезд, — отвязать оленей, оседлать и понестись гурьбой: «Э-э-эй!» Соревновались, кто быстрее. Летишь, а потом в лужу — бац! Весело было!',
        'В пять лет меня уже сажали на оленя, подушки укладывали, он и шел за аргишем сам. Но как-то раз я отстал, а олень за стадом побежал, а тут ручей — ну, он прыгнул, а я в воду плюхнулся. Сижу реву — он возвращается, носом тыкается, типа, вставай, поехали дальше. А я на него сержусь, кричу: «Иди отсюда!» Хорошо, брат заметил, прискакал, усадил обратно. Пока до дома ехали, я успокоился.',
        'Олень может бегать со средней скоростью 40 километров в час, максимально — 60. Это домашний. А дикий — и до 80! Иногда на снегоходе едешь рядом, на спидометр смотришь — 70, и не можешь догнать! Но я один раз стадо догнал своим ходом — когда они летом от меня к горам убежали от комаров.',
        'Оленям мы давали имена, например Филиппок, Харбин, Чайка, Барон. Старшие братья фильмов насмотрятся и имена оттуда дают. У нас имелся телевизор с размером экрана как пачка сигарет «Беломорканал» — может, помните? Вот по нему как раз тогда «Санта-Барбару» показывали. Включали черно-белый, сидели в балкé по 10 человек, смотрели. Детям запрещали, но ты мог где-нибудь сзади спрятаться.',
        'После школы я пошел работать в тундру. Это называлось нагульная бригада. Осенью брали от стада сто голов и гнали на забой для поселка, для совхоза (он тогда еще существовал). И надо было так каждый год делать. Отбирали стареньких оленей, которые на будущий год, понятно, уже не выживут. Меня, кстати, даже в армию не отпустили — комиссовали, чтобы оставить в бригаде, сказали, давай работай! Да и жизнь в тундре уж точно покруче всякой армии будет. Но я в последний, крайний, год как раз попал — потом уже никто так не ездил, верхом не ездили, ну разве что пара человек, старики одни оставались… А так, пока я работал, мне нужно было добыть 15 диких оленей, пушнины 30 штук (песцы там, зайцы попадались), план это был у нас. Разделывал сам. Отец научил, как правильно стрелять, чтобы шкуру не повредить, как пялить. Все навыки, кстати, до сих пор у меня остались с того времени.',
        'Однажды я на дикого оленя зимой поехал. Еду-еду, вижу: лежит один на снегу. Подхожу ближе — не встает, потом поднялся, я его с собой забрал. И он у меня все ел — рыбу, мясо, все! Да, они и рыбу едят. У меня приятель однажды поставил капкан на песцов с рыбой мороженой, так соседский олень пришел и начал лизать, а его за нос — бац! — и прижало. Дня два он так носом в капкане и сидел, бедный. Мы пришли, открыли капкан — он сразу убежал. Им же соль нужна, они ее ищут везде. Вот даже когда порошком постираешь белье, развесишь, эти приходят и давай его жевать! Гоняешь их потом…',
        'Но вообще олень — умное животное. У нас случай был. Аргишились я, отец, мама, 20 голов взяли и поехали в тундру. Девяносто километров отмахали — надо было осенью зимний балóк привезти по тонкому льду. Двух оленей мы оставили, остальных отпустили — а они взяли и убежали. Пурга была, метель, следов нет, отец ищет везде — не находит, я на снегоходе помчал — тоже безрезультатно. А через три дня эти олени сами по своим старым тропам пришли обратно. У них компас, наверное, свой есть. Они и реку перешли!',
        'Бывало еще, дедушка где-нибудь на празднике выпьет, захмелеет, в санях на обратном пути уснет, а олень его сам довезет и у дома встанет. Как такси! Услуга «Трезвый водитель».',
        'В 2000 году последний День оленевода был у нас в поселке, потом народ весь уехал, но каждый год этот праздник отмечаем! В апреле Попигай оживает, съезжаются оленеводы из ближних поселков, из Якутии. А праздник проходит — и опять один человек там остается, сторож, «мэр города», как мы его называем. До сих пор там живет. Почему именно в апреле? Ну, весна, солнце, да и олень еще не такой худой — потом у него копытца мокнут, и он просто ходит, не кушает ничего. Олень долго может без пищи: у него в желудке специальный мешок есть, он его набивает, а потом обратно это отрыгивает — и снова ест. И так по много раз. Главное — давать им два — три часа спокойно полежать. Такое только у оленя есть!',
        'Как отличить дикого оленя от домашнего? Очень просто: дикий светлее, потому что обветренный и на солнце вылинявший, и у него задние ноги длиннее передних. Кстати, сейчас дикие стали к нам приходить — черные-черные, и рога большие…',
        'Жизнь в тундре многому учит. Мы рано взрослеем. Понимаем, что надо заботиться о родителях, других родственниках, помогать им. К 12 годам ребенок уже все умеет: мальчики вместо отцов могут выполнять все функции взрослого тундровика — управлять нартами, снегоходами, выпасать целое стадо оленей, отлавливать их, плести маут… Девочки вместе с мамой готовят пищу, выделывают шкуры, шьют одежду. Невесты мечты, да и только!',
        'Если бы не изменения в жизни в нашего государства, повлекшие за собой постепенный уход от традиционного уклада жизни долган, я бы обязательно, как мои старшие братья и сестра, и сейчас кочевал вместе с  родителями, со своей семьей по бесконечно дорогой мне тундре. Но оленеводство постепенно приходит в упадок. Одно за другим исчезли оленеводческие хозяйства в районе. Попигайцы, мои земляки, наверное, последние, кто пытается сохранить оленей — уже не совхозных, а собственных. Среди них мои отец и брат.',
        'Я не хотел ехать в колледж и дальше учиться. С оленями привык! Но родители сказали: надо! Если, мол, не получится — вернешься обратно. Ну я остался в городе, окончил колледж, стал художником-оформителем. Сейчас работаю резчиком по кости и рогу в Доме народного творчества.',
        'Хотя я живу в Дудинке, в душе себя считаю оленеводом. Как выпадает возможность, беру отпуск и весной либо летом отправляюсь в тундру. Душа просит! Раньше ездил каждое лето, оленей пас. Сейчас уже все по-другому: балкитянем на снегоходах, а раньше-то верхом. Между прочим, советский снегоход по тундре и без снега идет!',
        'Я сейчас совсем городским стал, но в тундре жил бы, конечно, с радостью. А вот моя семья уже точно не справится. Старшему сыну шесть, но он совсем не интересуется этим, а младший — маленький, два года. Но я буду стараться прививать им качества тундровика! Летом на рыбалку их беру. Вот сейчас на зубатку пойдем… Оленей они видели, катались, хотя и боятся. Ну это ж первый раз!',
        'Между мужчинами и женщинами у нас равноправие, но есть запреты, которые мы и сейчас соблюдаем. Женщине вообще нельзя трогать вещи охотника. А мужчине — женские вещи или, там, мыть посуду! Шучу! Мужчина, конечно, у нас в семье главный, но в тундре-то надо все уметь: если жена рожает где-то, то он и за детьми следит, и зашить себе что-то может. А вот женщине не обязательно уметь все, что может мужчина. Феминисток среди долган нет! Кстати, сохраняется традиция, что за невестой обязательно должно быть приданое. Олени, конечно же! Количество зависит от достатка семьи, но главное — чтобы на аргиш хватило, раньше достаточно было 20–30 голов. Плюс парка зимняя, одеяла на песцовом меху, возможно — балóк...',
        'Жену я встретил не в тундре. Здесь, на работе познакомились. Она эвенкийка. Каков идеал женщины у нас? Маленькая должна быть, чтобы на олене могла поместиться, перевозить чтобы легче! Глаза должны быть красивые… И без краски чтоб была — не одобряю этой косметики.',
        'Воспитание детей в долганской семье должно оставаться традиционным: никакой морали, только личный пример. Важно, чтобы ребенок нутром чувствовал, как себя вести, и соблюдал традиции своего народа. Единственное нравоучение заключено в слове «ании», что означает грех. Если бабушки после очередных выходок детей произносили такое слово, значит, категорически нельзя этого делать. Даже невинные проявления некультурного поведения пресекались этим магическим словом.',
        'Мама с папой бóльшую часть времени сейчас проводят в поселке. Но при первой возможности мчатся к  своим балкáм. В тундре всегда дел невпроворот. Посушить зимнюю одежду, обновить покрытие балкá, отремонтировать нарты, снегоход… Папа очень тоскует по традиционному укладу жизни, по советскому времени. Тяжело смотреть, как, чуть выпив, дает волю своим чувствам. Из-за необходимости часто обращаться в больницу мои родители недавно приняли нелегкое для себя решение: продать оленей и купить квартиру в Хатанге. Да и нам, детям, внукам, чтобы было где остановиться по приезде в районный центр.',
        'Ненцы говорят, что неприлично спрашивать, сколько у тебя оленей. Это потому что они сосчитать не могут! У них большие стада, и оленеводы они лучшие. Это мы, долганы, признаем и даже не думаем обижаться. Но и у нас ведь тоже, пока гоним, не сосчитаешь — только когда в кораль (загон для оленей) поставил. Летом по 12 семей собираемся вместе — так проще ухаживать. А чтобы не перепутать, где чьи, метки делаем — обычно первую букву фамилии. Если же несколько семей Чуприных, то добавляем инициалы. Либо ленточки вяжем на ухо — каждый год разного цвета. Пробовали в 2018-м чипировать, но аппарат все время ломался. Уши-то маленькие, рвались, мы не могли чип закрепить… Цифровизация не прошла! Стада частные сегодня маленькие — по 700–800 голов всего.',
        'Я хотел бы вернуться в тундру, заниматься с оленями, но это маловероятно. Заявку на грант написать надо, и люди нужны подвижные, заинтересованные — я  же один все это не потяну. Там каждый день нужно пахать, кораль сделать для начала. Даже туристическую базу можно было бы придумать. Показывать традиции, вести хозяйство, территорию большую взять, петли ставить на куропаток, песца, зайца. А охотники бы и на дикого ходили.',
        'Боюсь, что традиция оленеводства в нашей семье на мне пресечется. Но есть у меня мечта: взять сто голов и уйти куда-нибудь в тундру, жить там, чтоб семья рядом была. Я бы с собой перевез — ненцы же так и ездят. Даже на Хантайское озеро (хоть это и о-о-очень далеко) можно было бы поехать — места есть хорошие, там бы обосновались. В принципе, осуществимая идея — не в космос же полететь. Купил бы хотя бы 15 важенок (в прошлом году одну можно было за 9–12 тысяч рублей взять), ну и потом телята… Быков хотя бы три…',
        'Так что, если мои сыновья все-таки решат в тундру уехать, я их, конечно, поддержу!',
        'Хочу похвастаться: моих родителей и семью моего брата весь мир, может быть, увидит в фильме «Альтамира» великого испанского документалиста Хосе Луиса Лопес-Линареса, который вместе со своей группой прожил неделю с ними в попигайской тундре. Но это уже совсем другая история…'
    ]
};

export default femini;
