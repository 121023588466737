import rogIcon from '../../assets/images/icons/56_rog_icon.png';
import frameIcon from '../../assets/images/icons/frame01.png';
import imgPhoto from '../../assets/images/articles/56_rog_ph.png';
import imgUzor from '../../assets/images/articles/56_rog_uzor.png';
import img01 from '../../assets/images/articles/56_rog_01.png';
import img02 from '../../assets/images/articles/56_rog_02.png';
import img03 from '../../assets/images/articles/56_rog_03.png';
import img04 from '../../assets/images/articles/56_rog_04.png';
import img05 from '../../assets/images/articles/56_rog_05.png';
import img06 from '../../assets/images/articles/56_rog_06.png';
import img07 from '../../assets/images/articles/56_rog_07.png';
import img08 from '../../assets/images/articles/56_rog_08.png';

const rog = {
    id: '56',
    link: 'rog',
    icon: rogIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [
        { image: img01, label: '' },
        { image: img02, label: '' },
        { image: img03, label: '' },
        { image: img04, label: '' },
        { image: img05, label: '' },
        { image: img06, label: '' },
        { image: img07, label: '' },
        { image: img08, label: '' }
    ],
    title: 'Сначала сказка придумывается, а потом нужен рог',
    name: 'Алексей Николаевич Чунанчар',
    role: 'Резчик по кости и рогу, сотрудник КГБУК «Таймырский дом народного творчества»',
    nation: 'нгасан',
    authors: {
        title: 'Подготовила',
        names: 'Мария Юрцив',
        mediaschool: 'Норильск',
        photoNum: 'many',
        photoNames: 'Лада Мягкова, Александр Химушин'
    },
    text: [
        'Я 17 лет проработал в «Чуме» косторезом. Потомственный нганасанский сказитель — мой папа был сказителем, а я продолжаю его дело. Играю на баргане, исполняю народные песни. Недавно побывал в Женеве на выставке фотографа Александра Химушина «Мир в лицах», представил там стилизованное камлание шамана (потому что настоящее нам делать нельзя, разумеется, — у меня в роду шаманов не было). А еще выступил в роли сказителя и модели. В «Большом чуме» фотографу оборудовали студию, куда приглашали коренные народы. Шесть человек из наших там он отснял.',
        'Живу в Дудинке с 2003 года, а родом из поселка Волочанка. Сначала учился в Норильске в колледже искусств, потом переехал в Дудинку к родственникам. Родители остались в поселке. Мама — швея, а папа при жизни был сказителем, рыбаком и оленеводом. Вырезать я научился в колледже искусств в Норильске, но есть вещи, которым меня научил отец, например делать ложки, санки, ручки для ножа.',
        'В основном я вырезаю из рога оленя. Сама природа подсказывает форму будущего изделия, я лишь слегка добавляю или убираю. В школе и в интернате я много рисовал — тундру, оленей. Я ведь рос среди них, был оленеводом, а также рыбачил и охотился. В колледже меня научили работать с костью, а так бы рисовал до сих пор.',
        'Я сам выезжаю на поиски материала, знаю, где в тундре можно его найти. Смотрю на рога и сразу вижу, что из них сделаю; более грубые выставляю на продажу. Вырезаю не только из рогов оленя, использую и бивни мамонта. Они более желтые. Их тоже нахожу сам. Когда вода в реке летом немного убывает, бивни и показываются.',
        'Рога чаще всего не склеиваю, а соединяю штырями или беру целиковые. Вырезаю только в Доме творчества, в оборудованной мастерской, потому что здесь есть специальные приборы, вытяжка хорошая. Дома будет слишком пыльно. К тому же бивни, которые я нахожу, могут быть разного возраста и дышать ими очень вредно — неизвестно, сколько лет мамонт там пролежал и от чего умер. А дома дети…',
        'Все работы в основном посвящены моему народу. Вот, например, смотрите: это камлание шамана. Каждая его татуировка что-то означает. А вот пять женщин, которые его охраняют: старуха отгоняет от него злых духов. Когда к шаману обращаются за помощью, он начинает летать к верхним, нижним и средним духам, чтобы найти, скажем, начало болезни ребенка. Это нужно, чтобы злых духов прогнать. Шаман может быть в женском одеянии, потому что иногда ему требуется женская энергетика, чтобы помочь решить какую-либо проблему. К этой работе я еще добавил бусинки, чтобы вокруг шамана была не только тьма, но и добрые духи — он же идет не со злыми намерениями, а чтобы помочь.',
        'В школе у сына был конкурс поделок на тему «История о мышонке». Ну, я долго не думал, взял рог, немного обработал, ушки добавил. Ничего особенного не делал! Ведь каждая трещинка может показать, чем станет изделие. Некоторые посмотрят на рог, увидят трещину: ой, это уже брак, не пойдет! И выбрасывают. А я знаю, что любой брак важен, в нем обязательно есть что-то подходящее. Я  покрутил, посмотрел, что-то отрезал, повернул, поставил — и вот уже какой-нибудь зверек на тебя глядит. Вроде и хитрый такой, и простой. Многие скажут: «А-а, я тоже так хотел сделать!» А на самом деле не додумались, голова до этого не дошла… Сын унес поделку — первое место занял!',
        'Эта работа называется «Шаман подчищает воину доспехи». Такой обряд проводили перед охотой или перед войнами. Да, войны были, но о-о-очень давно! Сражались с долганами, с энцами, эвенами — татуированными людьми, которые воровали у нганасан жен и увозили к себе.',
        'А вот это называется «Борьба хариги». Нет-нет, тут никто никого не бросает, не тревожьтесь! Это подземные люди, они выходят на землю только ночью, и как вылезут, начинают играть. В этой работе я представил одну из таких игр.',
        'А вот тут отразил три мира: воздушный — птица яйца высиживает, средний — олень с теленком, и нижний —  рыба с икрой.',
        'А это вот ворон, он показывает как бы путь из прошлого в настоящее своему роду воронов. Сказания, между прочим, говорят, что у нас раньше только белые вороны летали, а потом угодил один из них в костер, обгорел, и от него пошли черные. А белых со временем совсем не осталось…',
        'Чунанчар — фамилия княжеского народа. У нас было самое большое стойбище, много оленей. У меня есть работы, посвященные моим предкам. Например, вот этот чум, вокруг которого семья. Это глава семьи, он держит рыбу. Это напоминание о том, что в роду у нас были раньше и рыбаки, и охотники, и оленеводы.',
        'Многие не в состоянии распознать, отличить друг от друга людей разных коренных народностей Таймыра. А я могу — по чертам лица. Продолговатые лица и резко очерченные глаза — это или якуты, или долганы. Кругленькие лица с маленькими глазами — ненцы. У энцев тоже круглое лицо, но глаза пошире. У эвенков лица более восточные, внешне они похожи на китайцев. У нганасан скуластые лица, нос немного выпирает, уходит вперед, а вообще у нас имеются черты всех других малочисленных северных этносов — мы же самый древний народ Таймыра.',
        'Чем еще отличаются нганасаны? Я считаю, что они сильные, крепкие (сильнее ненцев), настойчивые, но в то же время добрые и простые.',
        'Я делаю бубны, а барганы — нет. Этому нужно специально учиться. Поэтому барганы заказываю хакасские, тувинские, из Якутии — они звонче и больше подходят под мой голос, лучше звучат. Играть на баргане несложно. Нужно держать язык подальше, а то можно прищемить, и надо, чтобы язычок гулял. Ну и вот так глубоко вдыхать и выдыхать, потому что, когда начинаешь играть, говоришь какое-нибудь слово, например «мама» или «папа», — и звук уже такой глубокий должен пойти: «У-а-у-а-у». Это главное, чему нужно сначала научиться, а потом уже можно свободно экспериментировать.',
        'Мои дети — тоже увлеченные люди. Старшая дочь сейчас в 11-м классе, играет на баргане и может поприветствовать гостей на родном языке. Ее приглашали на большую елку в Москву, она там играла. Она художница, сейчас учится в художественной школе имени Бориса Молчанова, хочет заниматься графикой. Отдает предпочтение этническому стилю. Мечтает народную одежду рисовать, но не красками, а в графическом стиле. А сыну очень нравится выступать. Он играет и на баргане, и на бубне. Я сделал ему маленький бубен, теперь на сцене мы вместе камлаем.',
        'Мне кажется, что у всех коренных народов Севера очевидна склонность к художественному творчеству. Все рисуют, шьют… Талант есть у каждого. Вот взять маленького пацана, любого попроси: «Слепи из снега что-нибудь такое»! Хоп-хоп — быстро сделал и убежал играть дальше, и славы ему не нужно! Мы все творческие.',
        'Честно: не могу даже объяснить, у меня природное это или нет? Просто воображение надо включить, фантазию, мозгами пошевелить — и что-нибудь выйдет. Я ведь даже эскизы никакие не рисую — как увидел, так и вырезал. И сказки, которые я рассказываю, придумываю сам, опираясь на предания своего народа. Какие больше люблю — страшные или про любовь? Даже не знаю… Какую попросят, такую и рассказываю. Записывать не записываю, а воплощаю в свои работы. То есть сначала сказка придумывается, а потом нужен рог!'
    ]
};

export default rog;
