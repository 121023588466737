import igrayIcon from '../../assets/images/icons/24_igray_icon.png';
import frameIcon from '../../assets/images/icons/frame05.png';
import imgPhoto from '../../assets/images/articles/24_igray_ph.png';
import imgUzor from '../../assets/images/articles/24_igray_uzor.png';
import img01 from '../../assets/images/articles/24_igray_01.png';

const igray = {
    id: '24',
    link: 'igray',
    icon: igrayIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [{ image: img01, label: '' }],
    title: 'Неважно, долганка ты или ненка, главное — играй хорошо!',
    name: 'Алиса Гуляева',
    role: 'Ученица дудинской гимназии',
    nation: 'долганка',
    authors: {
        title: 'Подготовила',
        names: 'Майя Балыбина',
        mediaschool: 'Дудинка',
        photoNum: 'single',
        photoNames: 'автора'
    },
    text: [
        'Привет! Меня зовут Алиса Гуляева, мне 15 лет. Я живу в Дудинке и занимаюсь баскетболом, играю за дудинскую гимназию. Баскетбол сам по себе очень популярный вид спорта, но, к сожалению, не у долган. Молодежь коренных малочисленных народов Таймыра чаще увлечена национальными видами — например, северным пятиборьем, а я вот выбрала игру с  мячом. Нечасто, но все-таки я вижу долган в командах-соперниках, у меня даже есть среди них друзья, но в целом я не обращаю внимания на национальность игроков. Какая разница, кто ты? Главное — чтобы играл хорошо!',
        'Честно говоря, я не особо увлекаюсь культурой долган: не знаю язык, мало знакома с фольклором. Но иногда мы с классом ходим на мероприятия в Таймырский краеведческий музей или Таймырский дом народного творчества. Там всегда очень интересно рассказывают про традиции и обычаи коренных северных народов. Я думаю, очень важно сохранять и поддерживать их культуру, иначе она потеряет свою индивидуальность. Но мы живем в современном мире, где все очень быстро меняется: люди уезжают из тундры, все меньше говорят на родных языках. Мое мнение: это дело каждого. Кто-то свяжет свою жизнь с языком и культурой своих предков, кто-то нет. Лично у меня планы на будущее абсолютно другие — никак не связанные с моей национальностью. Мои друзья совершенно не делают акцента на том, что я долганка, и никак не выделяют меня из-за нее.',
        'Баскетболом я занимаюсь два с половиной года. В секцию пришла вместе с подругами. Мы решили, что хотим больше времени проводить вместе, встречаться не только в школе, и дружно записались.',
        'Наша команда называется North Sharks, тренер зовет нас акулами! Я считаю, что мы — одна большая семья, а наши наставники нам как мама с папой! У меня есть ближайшая подруга Вика, с ней я всегда стою в паре на тренировках.',
        'С тренерами у нас доверительные отношения, мне с ними очень сильно повезло, они вкладывают в нас всю душу, тратят нервы, переживают. Всегда поймут, поддержат. С Ольгой Владимировной и Станиславом Николаевичем мы можем поговорить о чем угодно в свободное время. Мы ходили все вместе в кино, иногда с Ольгой Владимировной посещаем сауну всей командой. На подобных мероприятиях мы становимся еще ближе! Я бы больше времени проводила с командой, но мы все очень занятые люди! В свободное от тренировок время, разумеется, учимся, готовимся к экзаменам… Мы очень дружны, всегда готовы поддержать друг друга в сложных ситуациях, помогаем зарядиться перед игрой. На площадке мы как единый организм, понимаем друг друга с полувзгляда. Такое, кстати, встретишь не в каждом коллективе!',
        'Лучшей в команде я себя не считаю. Есть девчата, которые старше меня, опытней, у них лучше техника. К тому же каждая из нас хороша в своем. Кто-то отлично действует в защите, кто-то в нападении. Те, кто повыше ростом, прекрасно справляются с подборами, в такой ситуации сложно выделить лучшего. Я вот, например, не смогла бы стать капитаном. У меня отсутствуют лидерские качества. Наверное, в ближайшем будущем им станет Рада, которая действительно этого заслуживает, — вот она всегда может нас поддержать, воодушевить.',
        'Иногда я задумываюсь о будущем. Буду ли я профессионально заниматься баскетболом? Полагаю — нет. Планы другие: я собираюсь поступать в университет. Будет хватать забот, на баскетбол времени не останется. А вот просто поиграть в свободное время на улице, в свое удовольствие — я только за!',
        'Помимо баскетбола, я уже пять лет занимаюсь рисованием в детской школе искусств имени Бориса Молчанова, а также недавно окончила курсы кройки и шитья в «Юниоре» (в жизни пригодится!). Порой приходится пропускать одно ради другого, например во время соревнований или сдачи зачетов в художественной школе.',
        'Самый близкий для меня человек — мама. Она всегда меня поддерживает, переживает за меня, она главная моя опора. Я ее очень сильно люблю!',
        'В заключение хочу сказать, что всем людям необходим спорт, особенно подрастающему поколению! Лично мне баскетбол дал много навыков — как в физическом смысле, так и в социальном. Командная работа, взаимопонимание, дружба на площадке и вне ее — все это делает меня счастливой.'
    ]
};

export default igray;
