import marvelIcon from '../../assets/images/icons/32_marvel_icon.png';
import frameIcon from '../../assets/images/icons/frame03.png';
import imgPhoto from '../../assets/images/articles/32_marvel_ph.png';
import imgUzor from '../../assets/images/articles/32_marvel_uzor.png';
import img01 from '../../assets/images/articles/32_marvel_01.png';
import img02 from '../../assets/images/articles/32_marvel_02.png';
import img03 from '../../assets/images/articles/32_marvel_03.png';
import img04 from '../../assets/images/articles/32_marvel_04.png';

const marvel = {
    id: '32',
    link: 'marvel',
    icon: marvelIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [
        { image: img01, label: '' },
        { image: img02, label: '' },
        { image: img03, label: '' },
        { image: img04, label: '' }
    ],
    title: 'На северной тематике не усидишь, поэтому я вырезаю даже героев Marvel',
    name: 'Николай Михайлович Киргизов',
    role: 'Один из самых известных и знаменитых косторезов Таймыра, да и всей России. Участник огромного количества выставок, победитель множества конкурсов. Трудится он в отделе народного творчества Главного чума Таймыра — в их мастерской и рождаются его шедевры, большая часть которых потом остается на хранение в золотом фонде КГБУК «Таймырский дом народного творчества»',
    nation: 'долганин',
    authors: {
        title: 'Подготовили',
        names: 'Дарья Куклина, Дмитрий Малахов,',
        mediaschool: 'Дудинка',
        photoNum: 'many',
        photoNames: 'из архивов Таймырского дома народного творчества'
    },
    text: [
        'В основном я работаю с теми материалами, что есть на территории Таймыра. Чаще всего с костью мамонта, иногда с рогом оленя, редко — с берцовой костью мамонта. С клыком моржа или зубом кашалота дело иметь не приходилось — нет их у нас. Больше мне нравится бивень мамонта. По рогу оленя я редко работаю, может, раз в год или вообще не делаю такие заказы. В собственных целях бивень закупаю у охотников, которые часто их находят, а на работе нам материал выдают.',
        'Когда учился в колледже, мы вырезали скульптуры из дерева. И только с третьего курса стали работать с оленьим рогом. А потом, примерно на четвертом курсе, можно было получить право работать с бивнем, если преподавателю нравилось, как ты работаешь.',
        'Бивень мамонта — благородный материал, невосполнимый. А самое ценное дерево, наверное, черное. Мы в основном используем бук и березу. В работе и по кости, и по дереву в целом одинаковые знания применяются. Есть свои нюансы, конечно: из дерева можно сделать скульптуру и парковую, и миниатюрную. Когда режешь бивень, чаще всего занимаешься минималистикой, не можешь сделать большие композиции. Из него получаются маленькие статуэтки и ювелирные украшения.',
        'Бивень мамонта сам по себе очень капризный материал. На него влияет температура окружающей среды, он впитывает влагу, трескается на жаре. Чтобы этого не допустить, его нужно, условно говоря, консервировать — полировать маслами и специальными пастами.',
        'На фигурку среднего размера, если никто не мешает и ничто не отвлекает, уходит примерно неделя. Бывает, работа от настроения зависит, если оно есть, то ты можешь сесть и закончить все за пару дней, если нет, то дольше. Случалось, делал статуэтку полгода, год. Она лежит в столе — достал, поделал немного и обратно положил — ждать своего часа. Иногда сработал вроде быстро, а потом, спустя какое-то время, посмотрел на нее, и появляется желание что-то изменить, подправить. Иногда работа приедается, глаз устает. Тогда ее лучше забросить, через полгода вытащить и нормально доделать.',
        'Умение видеть в кости какое-то изображение приходит со временем. Когда я только начинал, мы разрабатывали эскиз, потом лепили модель и только после этого приступали к обработке материала. Сейчас я обхожусь без эскиза. Это приходит с опытом, практикой. Я не обрабатываю бивень, разбивая его на мелкие кусочки. Использую материал в той форме, в которой он попал ко мне в руки, со всеми его трещинками и неровными краями, вырезаю как можно меньше, чтобы его осталось в композиции побольше. Один бивень должен создать целостную композицию.',
        'Резьбе можно научить любого человека в любом возрасте — это никогда не поздно. Главное — усидчивость.',
        'Некоторые косторезы пытались раскрашивать свои работы. Между прочим, клиентам это даже очень нравилось. Но мне такое не по сердцу. Это нудно. Я не буду делать то, что мне не нравится. Зачем красить бивень мамонта, если ты можешь купить фарфоровую статуэтку и ее раскрасить?! Бивень мамонта нельзя покрывать ничем!',
        'Чаще всего мне заказывают фигурки мамонтов, оленей, то есть бренды Таймыра, его символы. Но создавал я и динозавров, и даже супергероев Marvel — Халка, например. Если бы я постоянно сидел только на северной тематике, у меня не было бы никакого развития. Кстати, среди моих заказчиков довольно много известных людей. Даже патриарху Кириллу делал три работы, сенатору Клишасу, спортсменам разным…',
        'Самые дорогие заказы — шахматы из бивня мамонта. Они потянут от 750 тысяч рублей и выше. Это большая и сложная работа.',
        'Знаете, что я заметил? Богатые люди очень жадные, всегда норовят сбить цену. А вот простой человек никогда не торгуется, он осознает, какой у меня сложный труд, сколько стоят материалы… Многие чиновники хотят получить что-нибудь бесплатно, названивают мне, но я просто не поднимаю трубку телефона.',
        'В Европе работы российских косторезов ценятся выше. У нас же истинную ценность фигурок из бивня понимают только коллекционеры. Их единицы. В Москве есть музей «Ледниковый период», там отличная коллекция, большое разнообразие работ: столы, стулья, трон, маленькие фигурки. Его владелец — коллекционер, он понимает и ценит резьбу по кости. Чукотская, якутская, тобольская резьбы все совершенно разные. Этот человек разбирается в стилях, умеет оценить работы по достоинству.',
        'Но в Москву я никогда в жизни не перееду, хотя мы часто летаем туда в командировки. Москва — это ужас, кошмар! Встаешь рано, вечером возвращаешься никакой, личной жизни вообще нет — все время уходит на дорогу и на сидение в офисе. Да еще там грязно! Были предложения переехать, но я отказался. Максимум для меня — это Красноярск. Там есть все, что нужно и сельскому человеку, и городскому. Мне больше нравятся провинциальные города, а интернет сейчас везде есть — общайся со всеми, с кем только пожелаешь!',
        'А вот работы свои через интернет стараюсь не продавать, не выкладываю их там. Однажды так сделал — потом пожалел. С повышением количества клиентов понижается качество. Для всех нужно все успеть сделать. У меня есть своя клиентская база, примерно одна и та же из года в год, хотя и расширяется немного. Если в интернет выложу свои произведения, потом завал будет. Зачем оно мне? Отдыхать-то ведь тоже надо.',
        'В свободное время увлекаюсь историей, читаю книжки. Кто лучший правитель в истории России? Мне больше всех нравится Сталин. Многие его обвиняют в репрессиях, в смертях людей. Но он не своими же руками это делал! Людей репрессировали, не потому что Сталин так захотел, а потому что простой люд писал доносы на соседей и даже на своих близких. В этом тоже Сталин виноват? Зато была проведена колоссальная работа по становлению промышленности, укреплению государства, за что ему спасибо!',
        'Как отдыхаю? Самое лучшее — это вылазки на квадроциклах, снегоходах либо на лодках в тундру, на охоту или рыбалку. Вот это настоящий мужской досуг! \n'
    ]
};

export default marvel;
