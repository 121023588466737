const menu = [
    {
        id: '001',
        name: 'Главная',
        link: '/'
    },
    {
        id: '002',
        name: 'Хранители',
        link: 'people'
    },
    {
        id: '003',
        name: 'О проекте',
        link: 'about'
    }
];

export default menu;
