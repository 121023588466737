import vkusIcon from '../../assets/images/icons/28_vkus_icon.png';
import frameIcon from '../../assets/images/icons/frame02.png';
import imgPhoto from '../../assets/images/articles/28_vkus_ph.png';
import imgUzor from '../../assets/images/articles/28_vkus_uzor.png';
import img01 from '../../assets/images/articles/28_vkus_01.png';
import img02 from '../../assets/images/articles/28_vkus_02.png';
import img03 from '../../assets/images/articles/28_vkus_03.png';
import img04 from '../../assets/images/articles/28_vkus_04.png';
import img05 from '../../assets/images/articles/28_vkus_05.png';
import img06 from '../../assets/images/articles/28_vkus_06.png';

const vkus = {
    id: '28',
    link: 'vkus',
    icon: vkusIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [
        { image: img01, label: '' },
        { image: img02, label: '' },
        { image: img03, label: '' },
        { image: img04, label: '' },
        { image: img05, label: '' },
        { image: img06, label: '' }
    ],
    title: 'Вкус детства — оленье молоко',
    name: 'Татьяна Николаевна Жаркова',
    role: 'Руководитель любительского объединения молодежной студии «Хилис» (hилис означает «корень растения или дерева») по культуре долган, знает и любит традиционную долганскую кухню',
    nation: 'долганка',
    authors: {
        title: 'Подготовили',
        names: 'Майя Балыбина, Елизавета Краузе',
        mediaschool: 'Дудинка',
        photoNum: 'many',
        photoNames: 'Лада Мягкова, Игорь Попов, Римантас Рамошка'
    },
    text: [
        'Я в Дудинке совсем недавно — переехала из Хатанги только в августе 2021 года, а сама родом из Попигая. Слышали, там еще метеорит упал? Попигайская астропроблема. На местных жителях, правда, это никак не сказалось — долгое время даже и не знали особо… И там у нас раньше добывали технические алмазы и много ценных пород. Сейчас я работаю в Таймырском доме народного творчества: рассказываю студентам нашего колледжа о культуре, традициях и обрядах долган.',
        'Родители тоже родом из Попигая. В семье нас четверо детей: двое братьев, сестра и я — самая младшая. Братья живут там же, они рыбаки и охотники. Всегда друг другу помогаем — они мне и сюда присылают мясо и рыбу. Я ничего не заказываю специально, они сами — что настреляли. Ну бывает, например, что папа захотел котлет из щуки — тогда ему щуку везут. В городе, конечно, можно купить и оленину, но вообще мы в поселках мясо никогда не покупаем — нас все время угощают. Когда жила в Хатанге, братья отправляли мясо и рыбу мешками (один — два килограмма для нас — это даже не мясо, так, на перекус), а мы уже сами их разделывали и готовили суповые наборы или фарш для котлет, пельмени сами лепили. По традиции долган в первую очередь делимся добычей со стариками и одинокими женщинами, у которых много детей.',
        'Я веду оседлый образ жизни, но знаю свои традиции. Соблюдаем их. Очень много среди долган православных, но они тем не менее не забывают и свои обычаи... Например, когда река открывается, мы идем проводить обряд бэлэктэнэр — стряпаем лепешки и подносим их реке, просим, чтобы все плохое уходило вместе со льдом, а хорошее пришло. Обряд очищения дымом алластыр проводится, если в семье происходит несчастье или кто-то заболел, или вот новое жилище нужно окурить обязательно: купили вы квартиру — надо очистить ее дымом. Идем по всем углам, по солнцу — у долган все обряды проводятся по солнцу. Проносим чашу, в которой лежит багульник или можжевельник и янтарь. Да, в Хатангском заливе есть янтарь — он белого цвета! Произносим: «Туркет-туркет», — что означает «все плохое уходи с дымом, все болезни, все ненастья».',
        'Передача всех знаний в большей степени, конечно, у нас происходит в семье: от родителей, бабушек, дедушек. Они рассказывают истории, учат, что можно делать, а что запрещено. Мы всегда слушаем их мудрые наставления, которые стараемся соблюдать. В отношении еды у нас много запретов. С малых лет к этому приучают — и дальше мы уже и не рассуждаем (может, так напугали), как привыкли, так и делаем. Вот, например, маленьким детям не разрешалось есть определенные продукты, такие как сычуг оленя. Ну то есть продолговатую часть можно, а морщинистую — нельзя. Существует поверье, что если нарушишь, быстро постареешь и лицо будет такое же морщинистое. Может, конечно, это еще и для того делалось, чтобы взрослым хоть что-нибудь осталось!',
        'А еще желудок оленя (внутренний мешочек) — кутуйа. Иногда его называют мунар — «блуждающий» в переводе. Его когда нарезаешь, нужно обязательно проговаривать специальные слова, иначе заблудишься в тундре или потеряешься где-то. Слова такие: «Сюда я зашла, отсюда вышла, туда пошла, отсюда пришла», — на долганском, конечно.',
        'Я сама очень люблю традиционную кухню и, разумеется, знаю, как готовили национальные блюда бабушка, мама моя. Но вообще специально у нас детей не учили готовить — времени на это просто не было, поэтому, если кто хотел научиться, тот просто вставал рядом и наблюдал.',
        'Наш главный деликатес — олений язык (тыл). Но кончик языка не ели, отрезали и выбрасывали — иначе будешь болтливым и сплетником. Возможно, там просто собираются какие-то вредные вещества, поэтому запрещают, я сама не знаю. Но нельзя и все!',
        'Мое самое любимое блюдо с детства — это пончики. Мама настряпает целый тазик, и мы целый день бегаем их кушаем. А из национальной кухни больше всего люблю суп hэлиэй, или бутугас. Это бульон из оленины с добавлением муки, он очень сытный. Варится без специй, только соль, и мясо обязательно должно быть жирным, со вкусом таким. ',
        'Есть еще такое блюдо, как амаhа, — его, правда, уже почти никто не готовит. Для его приготовления используется костный мозг оленя и тоже жирное мясо. Я ни разу сама не готовила, если честно, — не потому что сложно, а потому что где сейчас хорошую мозговую косточку достанешь?',
        'А вообще и дома, и для наших мероприятий я очень часто готовлю долганские блюда, такие как долганский хлеб, печень, сердце оленье. Сердце, кстати, нужно готовить до полуготовности (альденте, так ведь говорят, да?). Надо чтобы с кровью чуть-чуть, недоваривая, оно тогда мягкое, нежное получается. А если его хоть немножко передержать, оно становится жесткое и невкусное. Как поймать нужный момент? Не знаю, по времени тут не скажешь — я обычно на глаз все делаю.',
        'Знаю блюда других национальностей, потому что очень много сходств. Ведь олень один и набор продуктов тоже. Строганину, рыбу все едят, печень сырую тоже все едят. Все блюда из оленя и рыбы. Из специй всегда была соль, потом уже стали использовать лук, чеснок и перец. В тундре-то откуда приправы? Только травы — их для чая. Листья брусники, толокнянка (медвежьи ушки), чуть-чуть багульника. Добавляли ягоды: голубику, бруснику, шикшу, кислицу и морошку.',
        'Многие сейчас стали в наши блюда добавлять свинину, говядину, но я этого не делаю — только оленина!',
        'Разделкой мяса может заниматься мужчина, но вообще самую грязную и трудную работу в тундре выполняет женщина. Например, папа мне всегда говорил: «Хочешь строганины — почисти рыбу, а я построгаю». Женщина чистит рыбу, а мужчина строгает. Шкуру с рыбы полностью снимаем — это не так сложно, если она подмороженная. Щуку не строгают — ее только на котлеты, а многие долганы вообще не считают ее за рыбу, хотя в ней очень много полезных элементов. В основном ловят чира, сига, муксуна. Чир озерный — он самый вкусный, сытный, жирный! Даже не смогу объяснить вам, какой у него вкус… В городе я пробовала чира, но он совсем не такой… Или это я привыкла уже к тому…',
        'Для детей раньше делали заготовки молока. Оленьего домашнего. Замораживали в обычных мисочках, как раньше сахар разводили. После откалывали и давали ребятишкам. Идешь, и кусочек во рту тает, очень полезно и вкусно. Молока добывают очень мало — олень же все-таки не корова, его ведрами не доят, получается где-то кружечка, так как нужно оставить и телятам. С июля по октябрь оленей можно доить, а некоторые и целый год доят. Я сама всего однажды попробовала этот детский деликатес — мы же не кочевали, моей матери как-то привозили в качестве гостинца. Конечно, вкус очень интересный, вкус детства. Так-то из сладкого у нас были только ягоды. В моем детстве уже была мука, поэтому еще пекли пироги с ними и делали варенье. Сахар покупали, а природного не было.',
        'Деления еды на чисто мужскую или женскую у нас нет. В тундре все равны. И обязательно делаем заготовки — вот юколу из рыбы сушим, например. Раньше соли не было, обходились без нее, сейчас, конечно, с солью — и на солнышко, на ветер на несколько дней. Потом заносим в чум. Или даже специальный чум ставили для копчения. Хранится очень долго. Шили специальные мешки для этого. И так же сушим мясо — у него названия специального нет, просто каппыт эт (сушеное мясо).',
        'С наступлением осени, когда загоняют домашних оленей в кораль — это загон, чтобы прививку им сделать, — готовят такое блюдо: отрезают кусочек рога свежего со шкуркой и над костром чуть-чуть подпаливают, чтобы шерстка сплавилась, и чистят, вот эту кожицу едят. Панты вот эти. Они получаются немного горелыми, и очень долго жевать, и вкус проявляется не сразу. Даже не знаю, с чем сравнить, — ни на мясо не похоже, ни на что, какой-то другой вкус. У важенок тоже рога есть, конечно!',
        'Еще вот из крови оленя готовят кровяные колбаски, это также считается деликатесом. Когда сваренная, она становится белая-белая. Очень вкусно, я готовила! Но это только там, где есть домашние олени, и в городе такого не сделаешь, конечно. И из традиционных есть блюдо из муки, называется кэриэрди, или толокно. На оленьем жиру пережариваем муку до золотистого цвета и добавляем в горячий чай. Взрослые немного добавляют, в жидком виде пьют, а детям погуще на тарелочке — с молоком обычным или сгущенным. Получается очень сытно, малышам нравится.',
        'Забивают оленя только мужчины, это чисто их работа. Женщина уже потом помогает его разделывать — само мясо и внутренности. Жалко ли мне оленей? Ну конечно, я наблюдала много раз — это очень неприятная картина, но мы ведь тоже должны существовать.',
        'На День оленевода мы всегда готовим наши традиционные блюда. Чаще всего суп, котлеты рыбные и мясные, печень, сердце, печем хлеб. Долганский хлеб, он как лепешка, мука и дрожжи — получается очень пышным. Из куропатки готовим блюда. Правда, я не очень люблю — в ней мало мяса, а возиться долго приходится (я вообще птиц разделывать не люблю). Но из нее очень сытный получается суп. Из гуся лучше готовить весной — он же сезонная птица. Или замороженного можем и с яблоками запечь. Это сейчас, конечно. Но вообще духовок-то у нас не было — только то, что на костре можно пожарить на сковородке или сварить в котле, то и делали.',
        'Зайцев готовим, но не так часто — раньше-то пушнина ценилась, охотники все время сдавали шкуры, а сейчас уже такого большого промысла нет. А вообще в тундре ставились пасти, в которые клали приманку (рыбку или что), и когда зверек попадался, палка падала и пасти захлопывались — шкурка целой оставалась. Так и добывали — у одного охотника могло быть до 100 ловушек.',
        'Песцов не готовим, не-е-ет — это всегда было под запретом, потому что часто среди них встречается бешенство — наши предки считали, что они вообще все бешеные и мясо у них ядовитое. А вот мех использовали. Из шкуры шили одеяло, шубки и надевали мехом внутрь, а сверху — парку. Носить неудобно, но когда кочуешь, особенно зимой, без этого никак — главное, чтобы тепло. Еще надеваешь меховую обувь, бакари, галоши (я сама шила братьям), подошва шьется из подкопытного меха оленя (а его надо только в сыром виде шить, потому что если высохнет, то все…).',
        'Я не мастерица, конечно, но еще умею и шить, и вышивать бисером, хорошо знаю долганские орнаменты. В старину шили орнаменты подшейным волосом оленя. Вот это матака — сумка для перевозки вещей. Видите, здесь орнаменты прошиты подшейным оленьим волосом. Волос сначала скручивают, это очень трудно, а сшивают изделия сухожильными нитками. Мешки раньше, кстати, шили еще из рыбьей кожи, в основном щучьей. Она очень прочная и хорошо обрабатывается. А еще у нас, у долган, парки очень красочные — все цвета радуги на них! И отделка красивая — бисером. Сейчас я шью женские сумки из натуральной кожи или сукна, использую долганские орнаменты.',
        'Есть ли у нас специальные праздничные блюда? Да как-то нет, чтобы прямо только на праздник… У всех коренных народов же еда сезонная. Самый голодный месяц — август, потому что рыба не ловится, дикого нет, гусь на крыло встает, куропатку только весной можно. А с сентября начинается селедка, другая рыба. Ну язык по праздникам — считается же деликатесом, два часа его надо варить, не меньше! В магазине его не купишь — только мясо, печень. Строганину делаем. Главное, чтобы к празднику было самое свежее все — и мясо, и рыба.',
        'Медвежатину едим. Раньше были даже семьи, которые только этим промыслом и занимались. Помню, правда, как-то к нам в Старый Попигай пришел медведь по весне — пришлось убить, а когда я зашла к людям, которые его готовили, они мне сразу сказали: «Говори: кок-кок!» Кок — это ворон, и ты как будто обманываешь — что не ты медведя ешь, а ворон. Но у долган нет такого культа медведя, как у самодийских народностей. Почему? Многое подзабыли, да и запрещали много, влияли другие культуры.',
        'Мы ведь самая молодая народность — да, нас довольно много, но дело же не в количестве. А у нас на самом деле очень мало времени на то, чтобы сохранить свою культуру и свой язык — старики-то уходят. Конечно, по сравнению с другими странами у нас делается довольно много. Но только от самого этноса зависит сохранение его традиций — никакие государственные программы, никакие миллиарды не помогут, если он сам этого не хочет… А сохраняется это там, где есть образ жизни и естественная среда для языка и традиций. Вот я пока все равно думаю на долганском, несмотря на то, что нам раньше даже учителя запрещали говорить между собой на родном языке. И надо понимать, для чего сохранять, — для галочки или для того, чтобы жить в этом? Наше-то поколение сохранит, а вот дети…'
    ]
};

export default vkus;
