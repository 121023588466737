import cultureIcon from '../../assets/images/icons/46_culture_icon.png';
import frameIcon from '../../assets/images/icons/frame02.png';
import imgPhoto from '../../assets/images/articles/46_culture_ph.png';
import imgUzor from '../../assets/images/articles/46_culture_uzor.png';
import img00 from '../../assets/images/articles/46_culture_00.png';

const culture = {
    id: '46',
    link: 'culture',
    icon: cultureIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [{ image: img00, label: '' }],
    title: 'Знать культуру и язык своего народа просто необходимо',
    name: 'Иван Пальчин',
    role: 'Студент Таймырского колледжа',
    nation: 'ненец',
    authors: {
        title: 'Подготовила',
        names: 'Майя Балыбина',
        mediaschool: 'Дудинка',
        photoNum: 'single',
        photoNames: 'автора'
    },
    text: [
        'Привет! Меня зовут Иван Пальчин, мне 19 лет, я учусь в Таймырском колледже на учителя физической культуры. По национальности я ненец, жил в поселке Тухард, учился в Усть-Порту, после чего переехал в Дудинку.',
        'В поселке я занимался традиционным ремеслом: помогал отцу на рыбалке, охоте и в целом с кочевым образом жизни. Я прекрасно знаю свой родной язык и часто использую его в Дудинке, особенно для помощи новым студентам. Мои родители разводят оленей, младшие братья и сестры им помогают, мама сама шьет национальную одежду. Часть моих родных живет в Дудинке, некоторые тоже учатся в интернате. Сестра занимается акробатикой, а  братья — северным многоборьем, как и я. Родители приезжают к нам раз в год, но это не проблема: я часто езжу к ним, мы созваниваемся, они помогают деньгами, присылают продукты… Навещает меня и мой брат, ведущий кочевой образ жизни вместе с родителями. Мама и папа положительно относятся к моему переезду в Дудинку, ведь образование — это очень важно!',
        'Я считаю, что знать культуру, язык, жизненный уклад, традиции ненецкого народа просто необходимо! Стараюсь все это изучать и придерживаться наших обычаев, особенно приезжая к родным в тундру. Важно передавать все это из поколения в поколение, сохранять наши национальные особенности. В тундре быт и обычаи мы познаем непосредственно на практике, во время обрядов и в ходе обыденной жизни. Часто старейшины рассказывают старинные сказки и легенды, например об образовании племен, о знаменитых воинах, героях и другие.',
        'В Дудинке я занимаюсь северным многоборьем и футболом. Любовь к спорту и повлияла на выбор моей будущей профессии. Я много участвовал в соревнованиях и занимал первые и призовые места. Даже судил состязания по северному многоборью, а вот в роли тренера пока себя не пробовал.',
        'Пока не решил, где стану работать по окончании колледжа: уеду в поселок или останусь в Дудинке. Я уверен лишь в том, что моя профессия везде пригодится. По большей части мне, конечно, хочется вернуться к родным в поселок, к более привычному образу жизни, но окончательно я еще не решил.',
        'Учеба мне очень нравится, особенно практические занятия, но я понимаю, что без теории далеко не уедешь, все работает в синтезе. Преподаватели мне помогают с учебой и с личным развитием, я считаю, мне с ними очень повезло.',
        'Жить в городе после поселка достаточно трудно, но и очень интересно, ведь тут больше возможности для самореализации. Старшему поколению, например моим родителям, жизнь в городе не по вкусу, но я считаю, что нужно попробовать в этой жизни все. Даже если ты не определился с местом жительства, сможешь сравнить и впоследствии выбрать, что тебе больше по душе.'
    ]
};

export default culture;
