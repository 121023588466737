import namamonteIcon from '../../assets/images/icons/34_namamonte_icon.png';
import frameIcon from '../../assets/images/icons/frame04.png';
import imgPhoto from '../../assets/images/articles/34_namamonte_ph.png';
import imgUzor from '../../assets/images/articles/34_namamonte_uzor.png';
import img00 from '../../assets/images/articles/34_namamonte_00.png';
import img01 from '../../assets/images/articles/34_namamonte_01.png';

const namamonte = {
    id: '34',
    link: 'namamonte',
    icon: namamonteIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [
        { image: img00, label: '' },
        { image: img01, label: '' }
    ],
    title: 'Я не прочь покататься на мамонте',
    name: 'Ангелина Лырмина',
    role: 'Школьница',
    nation: 'ненка',
    authors: {
        title: 'Подготовила',
        names: 'Майя Балыбина',
        mediaschool: 'Дудинка',
        photoNum: 'single',
        photoNames: 'автора'
    },
    text: [
        'Здравствуйте! Я учусь в шестом классе, очень люблю шахматы!',
        'Сначала занималась ими в своей школе, а потом перешла в детский центр туризма и творчества «Юниор». Мои родители и все остальные родственники в шахматы играть не умеют, предпочитают шашки. Среди ненцев они довольно популярны. Исключение — моя старшая сестра, она в восьмом классе. Вот с ней мы регулярно садимся за доску. Кто чаще выигрывает? Я!',
        'Почему выбрала именно шахматы? Наверное, мне нравится думать! Этот вид спорта — особенный, не зависит от твоих физических кондиций, от роста, веса… Кто лучше соображает — тот и выигрывает. Кстати, после того как я начала заниматься шахматами, стала гораздо лучше учиться по математике и быстрее учить стихи! Домашнее задание теперь делаю за полчаса. А математика стала любимым предметом.',
        'Шахматы — это спорт или искусство? Мама говорит, что спорт, и я с ней согласна! Соревнования ведь проводятся по шахматам, чемпионаты мира и тому подобное — значит, точно спорт.',
        'Я мечтаю стать профессиональной шахматисткой! Первым гроссмейстером среди ненцев. По-моему, это отличная профессия!',
        'В «Юниоре» я играю чаще с мальчишками, потому что их больше в нашей секции. Лично мне все равно, с кем играть, но мне кажется, что мальчишки сильнее. Хотя по жизни девочки гораздо серьезнее мальчиков и более ответственно относятся к учебе.',
        'Кстати, я знаю, что некоторые знаменитые гроссмейстеры-женщины играли в мужских турнирах и одерживали много побед, например венгерка Юдит Полгар. Моя любимая шахматная фигура — ладья.',
        'Помимо шахмат, я занимаюсь акробатикой, а моя сестра — северным пятиборьем. Очень любою плавать — в бассейне или в море, когда мы с родителями бываем в теп­лых краях.',
        'Мне кажется, в Дудинке вполне мог бы состояться международный турнир по шахматам! Проходит же у нас керлинг? Играть можно в каком-нибудь красивом месте, например в музее. Кстати, если бы меня пригласили поприветствовать участников турнира, то я посоветовала бы им внимательно осмотреть экспозицию музея — она безумно интересная и познавательная! И еще я предложила бы им покататься на северных оленях. К сожалению, их не часто увидишь в городе, привозят только на праздники, а так надо ехать далеко в тундру… Ну и конечно, я бы пригласила иностранных шахматистов в наш Дом культуры, на концерт знаменитого ансамбля «Хэйро». У меня раньше папа в нем танцевал.',
        'Самый знаменитый экспонат Таймырского краеведческого музея — мамонтенок Женя. Хотела бы я увидеть живого мамонта, если его когда-нибудь клонируют и привезут на Таймыр? Конечно! Я бы даже хотела, наверное, на нем покататься! Уцепилась бы за шерсть… Это же так здорово!'
    ]
};

export default namamonte;
