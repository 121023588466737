import chipsIcon from '../../assets/images/icons/48_chips_icon.png';
import frameIcon from '../../assets/images/icons/frame03.png';
import imgPhoto from '../../assets/images/articles/48_chips_ph.png';
import imgUzor from '../../assets/images/articles/48_chips_uzor.png';
import img01 from '../../assets/images/articles/48_chips_01.png';

const chips = {
    id: '48',
    link: 'chips',
    icon: chipsIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [{ image: img01, label: '' }],
    title: 'Чипсы коренным народам совсем не полезны',
    name: 'Елена Спиридоновна Поротова',
    role: 'Врач-эндокринолог Таймырской межрайонной больницы',
    nation: 'долганка',
    authors: {
        title: 'Подготовили',
        names: 'Дарья Куклина, Арина Мышева, Максим Сундуков',
        mediaschool: 'Дудинка',
        photoNum: 'many',
        photoNames: 'Игорь Попов, Римантас Рамошка'
    },
    text: [
        'В состав Таймырской межрайонной больницы входит 11 структурных подразделений в поселках: семь фельдшерско-акушерских пунктов (Усть-Порт, Воронцово, Байкаловск, Волочанка, Потапово, Усть-Авам, Хантайское Озеро), Диксонская врачебная амбулатория, участковые больницы в Носке, Карауле и Тухарде. В Носке, где народу побольше, работают два врача, во всех остальных точках — по одному. Это терапевты или, как сейчас говорят, врачи общей практики. Если понадобилась помощь специалиста более узкой направленности, при неотложном случае вызывается санитарная авиация, вертолет, и больного вывозят к нам в Дудинку. Осмотрев пациента, решаем: либо здесь будет лежать, либо отправляем лечиться в норильскую больницу.',
        'Если нужно какое-то плановое обследование, мы выдаем направление по месту жительства, люди приезжают сюда в поликлинику, записавшись онлайн. Всем, кому мы даем направление, дорога оплачивается.',
        'К сожалению, оперативно оказать помощь получается не всегда. Если погода нелетная, мы зачастую ничего сделать не можем, сидим ждем. Бывают задержки до двух-трех дней. Всю посильную помощь оказывают на местах, а наши специалисты консультируют по телефону. Аппендицит, например, фельдшер сам вырезать не может. Колет антибиотики, ставит капельницы… И ожидает вертолет.',
        'Что поделаешь — расстояния у нас большие, климатические условия суровые. Помню, в поселке Хантайское Озеро внезапно начались роды. А там только фельдшер. Вызвали вертолет, санитарный рейс, но вдруг так задуло, что посадку совершить абсолютно невозможно. И никак иначе попасть в место назначения нельзя. Пока ждали, женщина благополучно родила. Слава богу, обошлось: и мама, и ребенок чувствовали себя хорошо, но, конечно, как только мы смогли, увезли их обоих в Дудинку.',
        'У нас часто рожают в поселке Носок, где самое большое тундровое население. Женщины ждут до последнего и иногда не успевают дождаться — рожают сами.',
        'Одно из главных отличий женщин коренных народов, тундровичек в том, что они не посещают регулярно женскую консультацию, не обследуются, не проверяют, нормально ли протекает беременность. Не потому, что не хотят, — не могут. Они находятся очень далеко, в 170 километрах — в беременном состоянии не наездишься. Но некоторые и не стремятся, считают, что рано или поздно вертолет за ними прилетит. И мы летим.',
        'Вообще коренные жители Таймыра, скажем так, не очень привержены обследованиям, не склонны лишний раз проверяться. Даже когда мы привозим в ближайшие к ним поселки целые бригады врачей, многие не приезжают из тундры, хотя знают о наших визитах. Наверное, это черта национального характера. Так и в XIX веке было, и в двадцатом, и сейчас. И столетие спустя, наверное, то же самое будет. Говорят, что нельзя оставить оленье стадо, хозяйство, детей…',
        'Есть ли какие-то специфические заболевания у тундровиков? Да нет, наверное. Такие же обычные недуги, как у всех. На первом месте заболевания органов дыхательной системы — и в городе, и в поселках. Простудам все подвержены. А вот обморожения чаще бывают у поселковых, чем у кочевых. Люди, живущие в тундре, в чумах, более закаленные.',
        'Я сама в тундре жила давно, в начале 1970-х годов, когда у нас еще были олени, — мой дядя их разводил. Я к нему приезжала в гости из поселка Волочанка, но оставалась буквально на день-два. Помню только очень большое стадо — маленькая была.',
        'Сейчас я постоянно в разъездах. Я же заместитель главного врача нашей больницы по медицинскому обслуживанию населения района. В прошлом году облетела 11 поселков, в двух побывала дважды, стало быть, 13 перелетов туда-сюда. Привозим специалистов разных профилей, проводим там по три-четыре дня. Помимо вертолета передвигаемся водным транспортом — ходим в Караул, Носок, Усть-Порт на Енисее, в поселок Потапово. Но это в летнюю навигацию. А буквально за три дня до вашего визита приехали с главным врачом на вездеходах — «Треколах» — из Караула и Носка. Зимняя дорога до Караула хорошая, можно доехать даже на легковом транспорте. Бывает, устаю, но в целом к такой жизни я привыкла.',
        ' В последнее время у коренных жителей Таймыра участились сердечно-сосудистые заболевания. Я считаю, это связано с изменением традиционного образа жизни, в первую очередь — питания. Раньше в рационе преобладали рыба и мясо, ну еще лепешки, теперь же, в принципе, есть все, в том числе конфеточки, колбаски, чипсы, сладкие газированные напитки. Это не идет нам на пользу. Заработали себе атеросклероз. Конечно, на функционирование желудочно-кишечного тракта изменение питания тоже повлияло, но еще больше — на сердце. Все чаще в последнее время стала встречаться ишемическая болезнь, инфаркты, инсульты… Такова обратная сторона прогресса…',
        'Могли ли шаманы лечить те или иные недуги? Ну по крайней мере один такой случай я знаю. В Усть-Аваме жил шаман, действительно обладавший какой-то такой силой. Некоторые болезни мог вылечить. Про него даже фильм сняли. Но медработников-то он не заменял! Как-то они уживались друг с другом.',
        'Наша национальная черта характера — упрямство. Упертость. Это касается не только долган, но и всех коренных. У нас говорят: если северный надулся, то больше ничего делать не будет. Я и сама это замечала: вот не хочу и не буду, никак человека не переубедишь. Но кстати, при этом много долган занимаются ученой деятельностью: преподают, выпускают буквари, книжки, развивают национальную культуру. А мой сын Кирилл, например, собирается учиться на эколога. Это правильно: профессия нужная и очень сейчас востребованная. Я вообще хочу сказать всем нынешним подросткам, не только коренным: ребята, надо учиться! Высшее образование получать или хотя бы среднее специальное! Ну не дворником же становиться! Дворник и зимой метет, и в дождь метет, и в снега метет, и будет мести, потому что у него нет образования. Вот удовольствие-то!',
        'Если бы я не стала врачом, то, наверное, пошла бы в педагоги. Хотя… Я учителей осматривала и по итогу очень их жалею. Почему? У них у всех расшатанная нервная система.',
        'На море я бывала во время отпуска. В Турции пару раз, в Тунисе… Но больше 10 дней я на пляже не выдерживаю. Не из-за жары. Когда летаю в гости к подружке в Минусинск — вот там жара, в котловинах до +40 градусов! Просто лежать загорать мне скучно. Не люблю однообразие…'
    ],
    memory: {
        title: 'Памяти доктора',
        text: [
            'Записывая это интервью, мы поразились открытости и доброжелательности Елены Спиридоновны. Общаться с ней было удовольствием, она терпеливо отвечала на все вопросы, хотя встретились мы в конце напряженного рабочего дня и нашей собеседнице наверняка хотелось побыстрее отправиться домой. Все мы попали под ее удивительное обаяние…',
            'И вот, когда книга готовилась к печати, из Дудинки нам сообщили страшное: доктора Поротовой не стало. Она ушла внезапно, скоропостижно, к ужасу ее сына, родных, друзей, коллег и тысяч пациентов, которым она помогала. Мы скорбим вместе с ними.',
            'Сын Елены Спиридоновны Кирилл разрешил нам использовать для издания состоявшийся год назад разговор, и мы очень ему благодарны! Пусть эта книга станет памятником замечательному врачу и человеку.'
        ]
    }
};

export default chips;
