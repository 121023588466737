import React from 'react';
import classes from './people.module.css';
import allPeople from '../../../data/articles/allPeople';
import Card from './card/card';
import { useParams } from 'react-router-dom';
import ArticlePage from '../articlePage/articlePage';

const People = ({ crossfade }) => {
    const { person } = useParams();
    return (
        <div className={classes.peopleWrap}>
            <div className={classes.bgr}></div>
            {person ? (
                <ArticlePage person={person} />
            ) : (
                <div className={classes.cardsWrap}>
                    {allPeople.map((item, index) => (
                        <Card
                            crossfade={crossfade}
                            key={item.id}
                            person={item}
                            index={index}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default People;
