import zabolelaIcon from '../../assets/images/icons/26_zabolela_icon.png';
import frameIcon from '../../assets/images/icons/frame01.png';
import imgPhoto from '../../assets/images/articles/26_zabolela_ph.png';
import imgUzor from '../../assets/images/articles/26_zabolela_uzor.png';
import img01 from '../../assets/images/articles/26_zabolela_01.png';
import img02 from '../../assets/images/articles/26_zabolela_02.png';

const zabolela = {
    id: '26',
    link: 'zabolela',
    icon: zabolelaIcon,
    frame: frameIcon,
    photo: imgPhoto,
    uzor: imgUzor,
    images: [
        { image: img01, label: '' },
        { image: img02, label: 'Иллюстрация: Елена Решко, ДХШ, Заполярный' }
    ],
    title: 'Когда я сильно заболела, дедушка вылечил меня оленьей кровью',
    name: 'Анна Ивановна Дюкарева',
    role: 'Сейчас на пенсии. Была редактором ненецких передач на Таймырском радио, помощником президента Ассоциации коренных малочисленных народов Таймыра. Почетный гражданин Дудинки и Таймыра. Имеет звания «Ветеран труда», «Отличник народного просвещения»',
    nation: 'ненка',
    authors: {
        title: 'Подготовила',
        names: 'Яна Дюкарева',
        mediaschool: 'Дудинка',
        photoNum: 'single',
        photoNames: 'из семейного архива А. И. Дюкаревой'
    },
    text: [
        'Меня зовут Анна Ивановна Дюкарева. Я родилась 26 марта 1942 года в семье оленевода. Детство пришлось на тяжелое для страны время. Трагедией была не только война, но и довоенные и послевоенные репрессии тоталитарного государства.',
        'Еще в 1938 году, до моего рождения, был арестован мой дед по материнской линии Кузьма Ефимович Лырмин. Его жизнь оборвалась 9 мая, тогда в Дудинке расстреляли более 20 тундровиков. А в 1947 году арестовали отца — Ивана Михайловича Пальчина. Пятилетняя я недоумевала, куда вооруженные люди повезли папу и зачем он летом взял с собой зимнюю одежду. Я запомнила его сидящим на корме удаляющегося катера, он смотрел на свою семью, которую больше уже никогда не увидел.',
        'У Ивана Михайловича Пальчина было сразу две жены: Мария Кузьминична и Ольга Кузьминична Лырмины. В тундре многоженство было распространено, так как мужчин не хватало, а одинокой женщине самостоятельно не прожить. У Марии Кузьминичны родилось шесть детей, а у Ольги Кузьминичны — трое, в том числе я. Мы росли большой дружной семьей, дети часто ходили за ягодами. Каждый понимал: чтобы выжить, надо много трудиться.',
        'Однажды я и мои две сестры наткнулись на волка. Увидев голодного худого зверя, мы побежали к дому. Самая младшая из нас, Тоня, постоянно спотыкалась и падала, ее унтайки были слишком велики (жили небогато, младшие донашивали обувь за старшими). Одна унтайка слетела с ее маленькой ножки, но мы не дали Тоне ее поднять, понимая, что жизнь дороже. Наш преследователь накинулся на потерянную обувь, пытаясь ее съесть. Это и спасло нам жизнь — волк подавился. На крики прибежала мать с палкой и прогнала задыхающегося зверя.',
        'Сестры и братья Пальчины окончили Усть-Портовскую среднюю школу. Жили в интернате, старшие опекали младших. В первый год учебы я сильно заболела, видимо, сказалась непривычная пища. Мой дед по отцовской линии (единственный оставшийся в семье мужчина) тайком забрал меня из интерната и вылечил оленьей кровью.',
        'В интернате поначалу было сложно. Учили разным наукам на русском языке. Когда нас только привезли туда, мы знали всего лишь одно русское слово — «материал». Мама часто его произносила, когда шила нам платья из ситцевой ткани. Молоденькая учительница что-то старательно объясняла, несколько раз повторяя «новый материал». Но к нашему разочарованию, она не показала нам ни кусочка ткани. Бывали и другие курьезные случаи, особенно на уроке музыки. Слова патриотической песни, которую мы пели, на ненецком языке имели неприличное значение. Получалось так, что герой гражданской войны оконфузился. Учительница ругалась на нас, когда мы покатывались со смеху, исполняя эту песню.',
        'Особенно потряс нас, маленьких девчонок, рассказ о Зое Космодемьянской. Самой главной заводилой в нашей компании была Любовь Прокопьевна Ненянг. Она хотела быть такой же отважной и несгибаемой, как Зоя, а мы не желали от нее отставать. И вот, когда воспитатели засыпали, мы босиком, раздетые, изо всех сил терпя холод, бегали вокруг интерната. Возможно, это закончилось бы обморожением, если бы нас не заметили.',
        'Между собой мы, дети, общались на родном языке. Он для меня так и остался языком дома, семьи. Я очень жалею, что мои внуки ненецкого языка не знают, но в этом моя вина. Мой сын вырос в русскоговорящей среде, но он хотя бы без труда понимает ненецкий. А внуки знают только отдельные слова.',
        'Язык — это самое главное для существования народа. Он отражает культуру, мировоззрение. Исчезнет язык — исчезнет народ. Поэтому мне небезразлично, на каких языках говорим мы сами и какие из них передаем детям.'
    ]
};

export default zabolela;
