import React from 'react';
import classes from './navbar.module.css';
import menu from '../../../../data/menu';
import { useLocation } from 'react-router-dom';

const Navbar = ({ crossfade }) => {
    const location = useLocation();
    return (
        <div className={classes.navbarWrap}>
            {menu.map(({ id, name, link }) => (
                <div
                    key={id}
                    className={
                        classes.navbarItem +
                        ' ' +
                        (location.pathname.includes(link) &&
                            link !== '/' &&
                            classes.activeItem)
                    }
                    onClick={
                        location.pathname.includes(link) &&
                        !location.pathname.includes(link + '/') &&
                        link !== '/'
                            ? undefined
                            : () => crossfade(link)
                    }
                >
                    {name}
                </div>
            ))}
        </div>
    );
};

export default Navbar;
